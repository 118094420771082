<script setup>
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import { watch, ref, onMounted } from "vue";
import { expediteStore } from "@/_store";
import { useStore } from "vuex";

const emit = defineEmits(['close']);
const store = useStore();
const bootstrap = store.state.bootstrap;
const detailStore = expediteStore();
const modalExpedite = ref(null);
const macInput = ref(null);
const mac = ref("");
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
onMounted(() => {
  let myModal = new bootstrap.Modal(document.getElementById("modalExpedite"), {
    keyboard: true,
  });
  myModal.show();
  if (macInput.value) {
    setTimeout(() => {
      macInput.value.blur();
      macInput.value.focus();
    }, 900);
  }
});
function searchShipment() {
  if (mac.value) {
    detailStore.getlabel(mac.value);
  }
}
function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function closeModal(){
  emit('close')
}
watch(
  () => detailStore.error,
  (value) => {
    if (value) {
      mac.value = "";
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => detailStore.label,
  (newVal) => {
    if (newVal) {
      mac.value = "";
      makeExport(newVal);
    }
  }
);
</script>

<template>
  <modal-base id="modalExpedite" ref="modalExpedite" @hide="closeModal">
    <template #header>Escanear MAC <i class="fas tags ms-3"></i></template>
    <template #body>
      <input
        ref="macInput"
        v-model="mac"
        class="w-100 form-control"
        :placeholder="'MAC'"
        @keyup.enter="searchShipment"
      />
    </template>
    <div class="position-fixed top-1 end-1">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
<style lang="scss">
.file-preview-container {
  gap: 1rem;
}
.drop-zone-area {
  max-height: 350px;
  overflow-x: auto;
}
.file-input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-input-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 80px;
  margin-right: 80px;
}
.drop-area:hover {
  border-color: #409eff;
}
.fa-upload {
  font-size: 20px;
  color: #67748e;
}
</style>