<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ArgonLoadingButton } from "@/components";
const router = useRouter();
const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");


const loading = ref(false);
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

function redirect() {
  router.push({ name: "Signing" });
}
</script>
<template>
  <main class="mt-0 main-content card-detail-layout">
    <section>
      <div class="page-header min-vh-100" :style="{
        backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
        <span class="mask opacity-3"></span>
        <div class="container text-center">
          <div class="row justify-content-md-center mb-6">
            <div class="w-50">
              <img class="img-fluid" src="/img/logos/BeePaketLogo.png" alt="logo" />
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card">
                <div class="card-header text-center">
                  <h3 class="font-weight-bolder">Solicitud Enviada</h3>
                  <p class="mb-0">
                    El email ha sido enviado, por favor revise su bandeja de entrada y sigua las instrucciones
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="redirect">
                    <div class="text-center">
                  <p class="mb-0"> Ó 
                  </p>
                      <argon-loading-button class="mt-4 mb-4" color="default" variant="gradient"
            :label="loading ? 'Redireccionando...' : 'Ir a login'" :loading="loading" full-width size="lg"></argon-loading-button>                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </section>
  </main>
</template>
<style scoped>
.mask {
  background-color: #ced4da !important;
}
</style>