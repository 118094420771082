import createAxios from './axiosHttp';

export const userService = {
  login,
  changePassword,
  tieneRol,
  esAccountAdmin,
  logout,
  oneShotToken,
  getRoles,
  newUser,
  cambiarEstadoUser,
  getEnlaceRecuperacion,
  resetPassword,
  getAllUsers,
  onDownloadFile,
  activate,
  deactivate,
  forwardPassword
};

function login(user, password) {
  const axios = createAxios();
  return axios.post('/login', { user, password }).then((userLogged) => {
    if (userLogged) {
      localStorage.setItem('user', JSON.stringify(userLogged));
    }
    return userLogged;
  });
}

function logout() {
  localStorage.removeItem('user');
}

function oneShotToken() {
  const axios = createAxios();
  return axios.get('/auth/token');
}

function getRoles(tipo) {
  const axios = createAxios();
  let urlPath = `/auth/roles`;
  if (tipo) {
    urlPath += `?tipo=${tipo}`
  }
  return axios.get(urlPath);
}

function newUser(user) {
  const axios = createAxios();
  let urlPath = `/user`;
  return axios.post(urlPath, user);
}

function tieneRol(user, rol) {
  return user.roles != null && user.roles.indexOf(rol) > -1;
}

function cambiarEstadoUser(user) {
  const axios = createAxios();
  const urlPath = `/auth/user/${user}`;
  return axios.put(urlPath);
}

function esAccountAdmin(user) {
  return tieneRol(user, 'ACCOUNT_ADMIN');
}

function changePassword(body) {
  const axios = createAxios();
  const urlPath = '/user/password/change';
  return axios.post(urlPath, body);
}

function getEnlaceRecuperacion(body) {
  const axios = createAxios();
  const urlPath = '/restore/password/link';
  return axios.post(urlPath, body);
}

function resetPassword(body, token) {
  const axios = createAxios();
  const urlPath = `/user/password/restore?ost=${token}`
  return axios.post(urlPath, body)
}
function getAllUsers(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `/user/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}
function onDownloadFile(fileName) {
  const axios = createAxios();
  let urlPath = `/blob/template/file/${fileName}`;
  return axios.get(urlPath,{ responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });;
}
function activate(body) {
  const axios = createAxios();
  let urlPath = `/user/activate`;
  return axios.post(urlPath, {ids:body});
}
function deactivate(body) {
  const axios = createAxios();
  let urlPath = `/user/deactivate`;
  return axios.post(urlPath, {ids:body});
}
function forwardPassword(body) {
  const axios = createAxios();
  let urlPath = `/user/forwardpassword`;
  return axios.post(urlPath, {ids:body});
}