<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import NotFound from "@/assets/img/image.png";

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: null,
  },
  shadow: {
    type: String,
    default: null,
  },
  borderRadius: {
    type: String,
    default: null,
  },
  circular: {
    type: Boolean,
    default: false,
  },
  zoomeable: {
    type: Boolean,
    default: false,
  },
});

const getSize = (size) => {
  if (size === 'mini') {
    return 'avatar-mini';
  }
  if(size === 'seguimiento') {
    return 'avatar-seguimiento'
  }
  return size ? `avatar-${size}` : null;
};

const getClasses = (shadow, circular, borderRadius) => {
  let shadowValue, circularValue, borderRadiusValue;

  if (shadow) {
    shadowValue = shadow === 'regular' ? 'shadow' : `shadow-${shadow}`;
  } else {
    shadowValue = null;
  }

  circularValue = circular ? 'rounded-circle' : null;

  borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;

  return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
};

function getImage(image) {
  if (!image || image === "") return NotFound;
  return image;
}

const isZoomed = ref(false);

const toggleZoom = () => {
  if (props.zoomeable) {
    isZoomed.value = !isZoomed.value;
  }
};
const handleClickOutside = (event) => {
  const imageElement = document.querySelector('.zoomed-in');
  if (isZoomed.value && imageElement && !imageElement.contains(event.target)) {
    isZoomed.value = false;
  }
};
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>
<template>
  <div class="avatar" :class="getSize(size)">
    <div id="product-gallery" class="my-gallery d-flex gap-3" style="height: 100%">
      <img
        :src="getImage(image)"
        :alt="alt"
        class="zoomable-image"
        :class="[getClasses(shadow, circular, borderRadius), getSize(size), { 'zoomed-in': isZoomed }]"
        @click="toggleZoom"
        style="width: 100%; height: auto;"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
.avatar-xxxl {
  width: 300px !important;
  height: 200px !important;
}

.avatar-404 {
  width: 200px !important;
  height: 200px !important;
}

.avatar-mini {
  width: 30px !important;
  height: 27px !important;
}

.avatar-seguimiento {
  width: 18px !important;
  height: 18px !important;
}

.zoomed-in {
  transform: scale(7.2);
  z-index: 1000;
  position: fixed !important;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%) scale(7.2);
}
</style>
