import createAxios from "./axiosHttp";

function getApiKeys() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/security/account/${user.extraData.accountId}/apikey`;
  return axios.get(urlPath);
}

function newApiKeys(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/security/account/${user.extraData.accountId}/apikey`;
  return axios.put(urlPath, body);
}

function deleteApiKeys(idKey) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/security/account/${user.extraData.accountId}/apikey/${idKey}`;
  return axios.delete(urlPath);
}

export const apiKeyService = {
  getApiKeys,
  newApiKeys,
  deleteApiKeys
};