import createPublicAxios from "./axiosHttp";

function getTracking(request) {
  const axios = createPublicAxios();
  let urlPath = `/tracking/${request.type}/${request.number}`;
  return axios.get(urlPath);
}

export const trackingService = {
  getTracking,
};
