/* eslint-disable */

// initialization of Tooltips
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { nextTick } from "vue";


export default {
  mounted(el) {
    nextTick(() => {
      el._tooltip = new bootstrap.Tooltip(el, {
        html: true,
      });
      el.addEventListener("click", () => {
        const tooltipInstance = bootstrap.Tooltip.getInstance(el);
        if (tooltipInstance._element) {
          tooltipInstance.hide();
        }
      });
    });
  },
  /* unmounted(el) {
    const tooltipInstance = bootstrap.Tooltip.getInstance(el);

    if (tooltipInstance && tooltipInstance._isEnabled) {
      tooltipInstance.dispose();
    }
  } */
};