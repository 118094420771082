<script setup>
import { watch } from 'vue';
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import { fulfillmentStore } from "@/_store";

const fulfillStore = fulfillmentStore();
defineProps({
  bundle: {
    type: Array,
    required: true,
  },
});
function printBarcode(info){
  fulfillStore.generateBarcode(info.mac)
}
function displayDocument(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => fulfillStore.barcode,
  (newVal) => {
    if (newVal) {
      displayDocument(newVal);
    }
  }
);
</script>
<template>
  <div class="bulto-card">
    <label
      ><bold>Bulto {{ `${bundle.order} / ${bundle.mac}` }}</bold></label
    >
    <button
      v-tooltip
      data-bs-title="Imprimir mac"
      class="btn btn-link btn-icon-only btn-sm text-dark mb-0 float-end"
      @click="printBarcode(bundle)"
    >
      <i class="fa fa-print text-xs" aria-hidden="true"></i>
    </button>
    <div v-if="bundle.items.length > 0" class="items-container">
      <div
        class="item-card"
        v-for="(item, itemIndex) in bundle.items"
        :key="itemIndex"
      >
        <div>
          <label
            v-tooltip
            :data-bs-title="item.product_name"
            class="text-dark text-xs mb-0"
            >{{ item.product_name }}</label
          >
        </div>
        <div class="d-flex flex-row">
          <argon-avatar
            class="me-2 ms-1"
            :image="item.image || NotFound"
            size="sm"
            shadow="sm"
            alt="Avatar"
          />
          <div class="d-flex flex-column">
            <span
              class="span-xs text-truncate w-100 text-bold"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              {{ item.variant_ean }}
            </span>
            <span class="span-xs">
              Cantidad: <span>{{ item.quantity }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <span v-else class="label-placeholder text-lg">Sin productos.</span>
  </div>
</template>

<style scoped>
.header h4 {
  margin: 0 0 10px;
}

.bulto-card {
  flex: 0 0 auto;
  width: 370px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.items-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  max-height: 170px;
  overflow-x: auto;
  overflow-y: hidden;
}

.item-card {
  border: 1px solid #eee;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  max-width: 165px !important;
  min-width: 165px !important;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.item-card label,
.item-card span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.card-detail-layout .span-xs {
  font-size: 0.75rem !important;
}
</style>
