<script setup>
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import ChangePassword from "@/views/profile/components/ChangePassword.vue";
import ApiKeys from "@/views/security/components/ApiKeysDetail.vue";
import { userStore } from "@/_store/userStore";
import { computed } from "vue";
const userStores = userStore();
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');

</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-detail>
        <template #body>
          <div class="row spacing">
            <div class="col-4">
              <change-password />
            </div>
            <div class="col-8" v-if="isAgency" >
              <api-keys  />
            </div>
          </div>
        </template>
      </argon-detail>
    </div>
  </div>
</template>