
<script setup>
import { onMounted, watch, ref, computed } from "vue";
import { Shops } from "@/globalVars";
import { useStore } from "vuex";
import ModalBase from "@/components/modal/ModalBase.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ArgonLoadingButton } from "@/components"
import ArgonSelectCourier from "@/components/ArgonSelectCourier.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import {  marketplaceStore } from "@/_store";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minValue,requiredIf } from "@vuelidate/validators";
import { objectUtils } from "@/utils";
import SelectMarketplace from "./ControlSelectMarketplace.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
const emits = defineEmits(['close', 'success']);
const usestores = useStore();

const bootstrap = usestores.state.bootstrap;

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
let departmentOptions = ref([])
const marketplaceOptions = ref(
  [
    { label: "Shopify", value: Shops.SHOPIFY },
    { label: "Prestashop", value: Shops.PRESTASHOP },
    { label: "Woocommerce", value: Shops.WOOCOMMERCE }
  ])

const modalShopRef = ref(null);
const disableButton = ref(false);
const disablePingButton = ref(false);
const isLoadingShop = ref(false);
const store = marketplaceStore();
const model = ref( {
    _id: "",
    app_token: "",
    secret: "",
    name:"",
    url: "",
    department: "",
    marketplace: "",
    international_service: "",
    national_service: "",
    default_package: { length: 0, width: 0, height: 0 },
  });
  const is_not_prestashop = computed(() =>
  model.value.marketplace!=Shops.PRESTASHOP
);
const rules = {
  app_token: {
    required: helpers.withMessage("App token es requerido", requiredIf(is_not_prestashop)),
  },
  secret: { required: helpers.withMessage("Secret es requerido", required) },
  name: { required: helpers.withMessage("Nombre de la tienda es requerido", required) },  
  url: { required: helpers.withMessage("Url es requerido", required) },  
  marketplace: {
    required: helpers.withMessage(
      "Marketplace es requerido",
      required
    ),
  },
  national_service: {
    required: helpers.withMessage(
      "Servicio nacional es requerido",
      required
    ),
  },
  default_package: {
    length: { 
      required: helpers.withMessage("Largo es requerido", required),
      minValue: helpers.withMessage("Largo debe ser mayor a 0", minValue(1))
    },
    width: {
      required: helpers.withMessage("Ancho es requerida", required),
      minValue: helpers.withMessage("Ancho debe ser mayor a 0", minValue(1))
    },
    height: {
      required: helpers.withMessage("Altura es requerida", required),
      minValue: helpers.withMessage("Altura debe ser mayor a 0", minValue(1))
    },
  }
};
const props = defineProps({
  id: {
    type: String,
    default: ""
  }
});
let key = ref(0);
let newDescription = ref(null);
let newDescriptionInternational = ref(null);
const keyLabel =ref(0);
const modelDefault = ref( {
    _id: "",
    app_token: "",
    secret: "",
    name:"",
    url: "",
    department: "",
    marketplace: "",
    international_service: "",
    national_service: "",
    default_package: { length: 0, width: 0, height: 0 },
  });
  
let v$ = ref(useVuelidate(rules, model));

onMounted(() => {
  getServices();
  if(props.id){
    store.getShop(props.id)
  }
  else{
    model.value= modelDefault.value
    key.value +=1
  }
  let dataClient = JSON.parse(localStorage.getItem('cliente'), null, 2);
  dataClient.departments = dataClient.departments.join(", ");
  assignDepartments(dataClient)
  let statusModal = new bootstrap.Modal(
  document.getElementById("modalShop"),
  {
    keyboard: true,
  }
  );
  statusModal.show();
});
const keyD = ref(0);
function assignDepartments(value){
  if (Object.keys(value).length>0){
    departmentOptions.value= []
    departmentOptions.value= value.departments?.split(',').map((dep) => {return {label: dep.trim(), value: dep.trim()}})
    keyD.value +=1;
  
  }
}
function closeModal() {
  emits('close');
}

function getServices() {
  store.getServices();
}


function getDescription(value){
  if(!value || value === '') return null;
  const foundService = store.services?.find(service => service.name === value);
  if(foundService != undefined && foundService != null) return foundService.description;
  else return null;
}

function update() {
  disableButton.value = true
  const contract = formatContract();
  store.updateShop(model.value._id, contract);
}

function create() {
  disableButton.value = true
  const contract = formatContract();
  store.createShop(contract, contract.marketplace);
}

function saveItem() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    if (model.value._id!= '') {
      update();
      return;
    }
    create();
  });
}

function pingStore() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    const contract = formatContract();
    disablePingButton.value = true
    store.pingShop(contract, contract.marketplace);
  });
}

function formatContract() {
  const contract = objectUtils.copyByValue(model.value);
  if (contract._id == "") delete contract._id;

  if (
    typeof contract.default_package.width === "string" ||
    contract.default_package.width instanceof String
  )
    contract.default_package.width = 0;
  if (
    typeof contract.default_package.height === "string" ||
    contract.default_package.height instanceof String
  )
    contract.default_package.height = 0;
  if (
    typeof contract.default_package.length === "string" ||
    contract.default_package.length instanceof String
  )
    contract.default_package.length = 0;

  return contract;
}

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

let servicios = ref([]);
watch(
  () => store.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val;
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);
watch(
  () => store.shop,
  (val) => {
    if (val) {
      model.value = val;
    }
    else{
      model.value= modelDefault.value;
    }
    key.value +=1;
  },
  { immediate: true }
);
watch(
  () => store.updateShopState,
  (value) => {
    if (value) {
      disableButton.value = false
      modalShopRef.value.closeModal();
      emits('success', 'Actualizada con éxito')
    }
  }
);

watch(
  () => store.createShopState,
  (value) => {
    if (value) {
      disableButton.value = false;
      modalShopRef.value.closeModal();
      emits('success', 'Suscripción activada')
    }
  }
);

watch(
  () => store.error,
  (value) => {
    if (value) {
      disableButton.value = false;
      isLoadingShop.value = false;
      disablePingButton.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message? value.message : "Lo sentimos, ha ocurrido un problema,intente más tarde",
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => store.pingShopState,
  (value) => {
    if (value) {
      disablePingButton.value = false;
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Conexión correcta</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);
watch(
  () => model.value?.national_service,
  (newVal) => {
    if (newVal) {
      newDescription.value = getDescription(newVal);
      keyLabel.value += 1
    } else {
      newDescription.value = null;
    }
  }
);
watch(
  () => model.value?.international_service,
  (newVal) => {
    if (newVal) {
      newDescriptionInternational.value = getDescription(newVal);
      keyLabel.value += 1
    } else {
      newDescriptionInternational.value = null;
    }
  }
);
watch(
  () => model.value.department,
  (value) => {
    if(value){
      departmentOptions.value=[{label: value, value: value}]
    }
  },
);
</script>
<template>
    <modal-base id="modalShop" ref="modalShopRef" width="50rem" @hide="closeModal">
    <template #header>
      Marketplace
    </template>
    <template #body>
      <form class="form-control border-0">
      <div class="row">
        <div class="col-2">
          <label  class="col-form-label">Marketplace</label>
        </div>
        <div class="col-10">
          <select-marketplace
            id="markteplace"
            :key="key"
            v-model="model.marketplace"
            name="marketplace"
            :error="v$.marketplace.$error"
            :options="marketplaceOptions" filterable show-icon>
          </select-marketplace>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label for="col-form-label" class="col-form-label">Nombre</label>
        </div>
        <div class="col-10">
          <argon-input 
          id="shop_name" v-model="model.name" :is-required="true" placeholder="" name="name"
            :max-length="100" :error="v$.name.$error" autocomplete="off" />
        </div>
      </div>
      <div v-if="model.marketplace!= Shops.PRESTASHOP" class="row">
        <div class="col-2">
          <label for="col-form-label" class="col-form-label">Token</label>
        </div>
        <div class="col-10">
          <argon-input 
          id="shop_app_token" v-model="model.app_token" :is-required="true" placeholder=""
            name="app_token" :max-length="100" :error="v$.app_token.$error" autocomplete="off"/>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label for="col-form-label" class="col-form-label">Secret</label>
        </div>
        <div class="col-10">
          <argon-input 
          id="shop_secret" v-model="model.secret" :is-required="true" placeholder="" type="password"
            name="secret" :max-length="100" :error="v$.secret.$error" autocomplete="off" />
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label for="col-form-label" class="col-form-label">Url</label>
        </div>
        <div class="col-10">
          <argon-input 
          id="shop_url" v-model="model.url" :is-required="true" placeholder="" name="url"
            :max-length="100" :error="v$.url.$error" autocomplete="off" />
        </div>
      </div>
      <div class="row">
          <div class="col-3">
            <label  class="col-form-label">Departamento</label>
          </div>
          <div class="col-9">
          <argon-select
            id="customer-department"
            :key="keyD"
            v-model="model.department"
            name="customer-department"
            :options="departmentOptions" filterable>
          </argon-select>
          </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label :key="keyLabel" v-tooltip for="col-form-label" :data-bs-title="newDescription" class="col-form-label">Servicio nacional<sup v-if="model.national_service != ''" class="text-primary sup-question">?</sup></label>
        </div>
        <div class="col-9">
          <argon-select-courier
            v-if="store.services" id="shop_national_service" :key="key" v-model="model.national_service"
            name="national_service" :is-required="true" :options="servicios" name-value="name"
            label-value="name" filterable :error="v$.national_service.$error" show-icon>
          </argon-select-courier>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label :key="keyLabel" v-tooltip for="col-form-label" :data-bs-title="newDescriptionInternational" class="col-form-label">Servicio internacional<sup v-if="model.international_service != ''" class="text-primary sup-question">?</sup></label>
        </div>
        <div class="col-9">
          <argon-select-courier
            v-if="store.services" id="shop_international_service" :key="key" v-model="model.international_service"
            name="international_service" :options="servicios" name-value="name"
            label-value="name" filterable   show-icon>
          </argon-select-courier>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <div class="col-6">
            <label for="col-form-label" class="col-form-label">Bulto</label>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row">

                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Largo (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input
                    id="length" v-model.number="model.default_package.length" :is-required="true" :error="v$.default_package.length.$error"
                    type="number" placeholder="" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Ancho (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input
                    id="width" v-model.number="model.default_package.width" :is-required="true" :error="v$.default_package.width.$error"
                    type="number" placeholder="" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Alto (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input
                    id="height" v-model.number="model.default_package.height" :error="v$.default_package.height.$error"
                    :is-required="true" type="number" placeholder="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </template>
    <template #footer>
      <argon-loading-button
        color="primary"
        variant="outline"
        size="sm"
        :label="disablePingButton ? 'Comprobando...' : 'Probar conexión'"
        :loading="disablePingButton"
        :disable-button="disableButton || disablePingButton"
        @click="pingStore"
      />
      <argon-loading-button
        color="primary"
        variant="gradient"
        size="sm"
        :label="disableButton ? 'Confirmando...' : 'Confirmar'"
        :loading="disableButton"
        :disable-button="disableButton || disablePingButton"
        @click="saveItem"
      >
      </argon-loading-button>
    </template>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
  </modal-base>
</template>
<style scoped>
.sup-question {
    font-size: 1.0em;
}

.spinner-size {
  width: 26px !important;
  height: 26px !important;
}

.text-primary {
  color: linear-gradient(310deg, #ea7c07 0%, #fa8f1d 100%);
}
</style>