<script setup>
import { onBeforeMount, onBeforeUnmount,ref,computed,onMounted, watch } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required,helpers, sameAs} from "@vuelidate/validators";
import { useUserStore } from "@/_store/userStore.js";
const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const router = useRouter();

const body = document.getElementsByTagName("body")[0];
const route = useRoute();

const userStore = useUserStore();
let email = ref(false);

const credentials = ref({
  newPassword: "",
  confirmPassword:""
})
const newPass=computed(()=> credentials.value.newPassword)
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
onMounted(() => {
  email.value = route.query.u;
});

const rules = {
  newPassword: {
    required: helpers.withMessage("Nueva contraseña es requerido", required),
    passwordPattern:  helpers.withMessage(
          () => ` Mínimo 8 carácteres, con alguna minúscula, mayúscula y número para Nueva contraseña`, 
          (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/.test(value)
        ),
  },
  confirmPassword:{
    required: helpers.withMessage("Confirmar Contraseña es requerido", required),
    sameAsNewPassword:   helpers.withMessage("Nueva contraseña y Confirmar contraseña deben coincidir",sameAs(newPass))
  }
};
const v$ = ref(useVuelidate(rules, credentials));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
const snackbar = ref(null);
function change() {
  const resValidation = validate()
  resValidation.then((values) => {
    let message= "";
    for (const value of values) {
      message = `${message}<li>${value.$message}</li>`;
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
    } else {
      let contract=credentials.value
      contract.user= email.value
      userStore.resetPassword({body:contract,token: route.query.ost});
    }
  });
}
const closeSnackbar = () => {
  snackbar.value = null;
};
watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => userStore.resetedPassword,
  (value) => {
    if (value) {
      localStorage.removeItem('user');
      router.push({ name: "Signing" });
    }
  }
);
</script>

<template>
  <main class="mt-0 main-content card-detail-layout">
    <section>
      <div 
      class="page-header min-vh-100" :style="{
        backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
        <span class="mask opacity-3"></span>
        <div class="container text-center">
          <div class="row justify-content-md-center mb-6">
              <div class="w-50">
                <img class="img-fluid" src="/img/logos/BeePaketLogo.png" alt="logo" />
              </div>
            </div>
            <div class="row  justify-content-md-center">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
                <div class="card">
                  <div class="pb-0 card-header text-center">
                    <h3 class="font-weight-bolder">Restaurando Contraseña</h3>
                  </div>
                  <div class="card-body">
                    <form id="resto-password-form"  @submit.prevent="change">
                      <div class="mb-3">
                        <argon-input 
                        id="new-password" v-model="credentials.newPassword" type="password" size="lg"
                          placeholder="Nueva contraseña"  :error="v$.newPassword.$error" />
                      </div>
                      <div class="mb-3">
                        <argon-input 
                        id="confirm-password" v-model="credentials.confirmPassword" size='lg' type="password"
                          placeholder="Confirmar nueva contraseña"  :error="v$.confirmPassword.$error" />
                      </div>
                      <div class="text-center">
                        <argon-button 
                          class="mt-4 mb-4"
                          variant="gradient"
                          color="default"
                          full-width
                          size="lg"
                          type="submit"
                        >Restablecer</argon-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  </main>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar 
    v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style scoped>
.mask {
  background-color: #ced4da !important;
}
</style>