<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
  position: {
    type: String,
    default: "start",
  },
  textPrimary: {
    type: [String, Number],
    default: "",
  },
  textSecondary: {
    type: [String, Number],
    default: "",
  },
  textThird: {
    type: [String, Number],
    default: "",
  },
  maxWidth: {
    type: String,
    default: "",
  },
  hasHide: {
    type: Boolean,
    default: false
  },
});

const idContainer = ref(`td-${props.maxWidth}-${Math.random().toString(36).substr(2, 9)}`);
const showTooltip = ref(false);
const isTruncated = (element) => {
  return element.scrollWidth > element.clientWidth;
};

onMounted(() => {
  if (props.maxWidth !== '') {
    const container = document.getElementById(idContainer.value);
    if (container) {
      showTooltip.value = isTruncated(container);
    }
  }
});



function getClass(position, textSecondary) {
  let pos = `text-${position}`;
  let align = textSecondary != "" ? "align-middle" : "";
  return `${pos} ${align}`;
}

function getStyle(maxWidth) {
  return maxWidth ? `max-width: ${maxWidth} !important; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;` : '';
}

function getTooltip(textPrimary, textSecondary) {
  if (props.maxWidth === "" || !showTooltip.value) return null;
  if (textSecondary) return textSecondary;
  if (textPrimary) return textPrimary;
  return null;
}

</script>

<template>
  <td
    :class="getClass(position, textSecondary)"
    :style="getStyle(maxWidth)"
  >
    <div
      :id="idContainer"
      class="d-flex flex-column justify-content-center"
      :class="{'text-truncate': showTooltip}"
      v-tooltip
      :data-bs-title="getTooltip(textPrimary, textSecondary)"
    >
      <p v-if="textPrimary" class="text-xs font-weight-bold mb-0">
        {{ textPrimary }}
      </p>
      <p v-if="textSecondary !== ''" :class="{ 'password-text': hasHide }" class="text-xs text-secondary mb-0">
        {{ textSecondary }}
      </p>
      <p v-if="textThird !== ''" class="text-xs text-secondary mb-0">
        {{ textThird }}
      </p>
    </div>
    <slot></slot>
  </td>
</template>

<style lang="scss" scoped>
.password-text {
  -webkit-text-security: disc;
  text-security: disc;
}
.text-truncate {
  p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
