import { defineStore } from "pinia";
import { apiKeyService } from "@/_services";

export const apiKeyStore = defineStore("apiKey", {
  state: () => ({
    error: null,
    list: null,
    delete: null,
    send: null,
  }),
  actions: {
    getApiKeys() {
      apiKeyService
        .getApiKeys()
        .then((results) => (this.list = results))
        .catch((reason) => (this.error = reason));
    },
    newApiKeys(body) {
      apiKeyService
        .newApiKeys(body)
        .then((results) => (this.send = results))
        .catch((reason) => (this.error = reason));
    },
    deleteApiKeys(idKey) {
      apiKeyService
        .deleteApiKeys(idKey)
        .then((results) => (this.delete = results))
        .catch((reason) => (this.error = reason));
    },
  },
});
