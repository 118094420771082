<script setup>
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ArgonLoadingButton } from "@/components";
import { ref, watch } from "vue";
import { notificationListStore } from "@/_store/notificationsStore";
let key = ref(0);
const notificationStore = notificationListStore();
const loading = ref(false);
const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const props = defineProps({
  notification: {
    type: Object,
    default() {
      return {};
    },
  },
  notificationActive: {
    type: Boolean,
    default: false
  }
});

const headers = [
  {
    header: "",
    field: "status",
    sort: false
  },
  {
    header: "Notificar a remitente",
    field: "to_sender",
    sort: false,
    position: "center"
  },
  {
    header: "Notificar a destinatario",
    field: "to_receiver",
    sort: false,
    position: "center"
  },
];
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

function labelStatus(status) {
  if (status === 'RECOGIDA_PENDIENTE') return 'Manifestado';   
  if (status === 'INCIDENCIA') return 'Incidencia';
  if (status === 'ENTREGADO') return 'Entregado';
}

const notifi = ref(props.notification);
const active = ref(props.notificationActive);

function saveNotifications() {
  loading.value = true;
  const filteredData = notifi.value.data.filter(item => item.to_sender || item.to_receiver);
  const active = filteredData.length > 0;
  const result = {
    triggers: active ? filteredData : [],
    active: active
  };
  notificationStore.sendNotifications(result);
}
function changeActive(){
  if(active.value){
    dropdownExpanded.value=true;
  }else{
    notifi.value.data.forEach(notification => {
      notification.to_sender = false;
      notification.to_receiver = false;
    });
    key.value++;
  }
}
watch(
  () => props.notificationActive,
  (value) => {
    active.value = value;
    key.value++;
  }
);

watch(
  () => notificationStore.notification,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
      loading.value = false;
    }
  }
);
/* watch(
  () => active.value,
  (value) => {
    if (!value) {
      notifi.value.data.forEach(notification => {
        notification.to_sender = false;
        notification.to_receiver = false;
      });
      active.value = false;
      key.value++;
    }
  }
); */
watch(
  () => notificationStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => props.notification.data,
  (newData) => {
    newData.forEach(item => {
      if (item.to_sender || item.to_receiver) {
        if(!active.value){
          active.value=true;
          key.value++;
        }
      }
    });
  },
  { deep: true }
);
const dropdownExpanded = ref(false);

function toggleDropdown() {
  dropdownExpanded.value = !dropdownExpanded.value;
}
</script>

<template>
  <div class="card">
    <div class="card-header">Notificaciones</div>
    <div class="card-body">
      <div class="row">
        <div class="col-10">
          <div class="d-flex align-items-center mb-3">
            <div>
              <a href="javascript:;" @click="toggleDropdown" class="text-sm text-body">
                {{ dropdownExpanded ? 'Ver menos' : 'Ver más' }}
                <i :class="dropdownExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" class="ms-1"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="float-end">
            <argon-switch id="enable-notification" :name="'notification'" :key="key" v-model="active" @change="changeActive"/>
          </div>
        </div>
      </div>
      <div v-show="dropdownExpanded">
        <simple-table :headers="headers" :items="notifi">
          <template #default="{ item }">
            <argon-td :position="getPosition('status')" :text-primary="labelStatus(item.status)"></argon-td>
            <argon-td :position="getPosition('to_sender')">
              <div class="mx-auto argon-switch-reduce" style="width: 65px !important;">
                <argon-switch id="sender" :name="'sender'" :key="key" v-model="item.to_sender" />
              </div>
            </argon-td>
            <argon-td :position="getPosition('to_receiver')">
              <div class="mx-auto argon-switch-reduce" style="width: 65px !important;">
                <argon-switch id="receiver" :name="'receiver'" :key="key" v-model="item.to_receiver" />
              </div>
            </argon-td>
          </template>
        </simple-table>
        <div class="card-footer">
          <div class="position-absolute bottom-1 end-1">
            <argon-loading-button class="btn btn-sm bg-gradient-primary" color="primary" variant="gradient"
              :label="loading ? 'Guardando...' : 'Guardar'" :loading="loading"
              @click="saveNotifications"></argon-loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style lang="scss" scoped>
.argon-switch-reduce{
  margin-bottom: -14px !important;
}
</style>
