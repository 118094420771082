<script setup>
import { ref } from "vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const props = defineProps({
  copiedValue: {
    type: Boolean,
    default: false,
  },
  valueToCopy: {
    type: String,
    default: "",
  },
});

const isCopied = ref(props.copiedValue);
const snackbar = ref(null);

const copyValue = async () => {
  isCopied.value = true;
  await navigator.clipboard.writeText(props.valueToCopy);
  setTimeout(() => {
    isCopied.value = false;
  }, 250);
};


const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <a v-if="!isCopied" class="ms-2" @click="copyValue">
    <i class="fa-regular fa-copy hover-copy" v-tooltip data-bs-title="Copiar"></i>
  </a>
  <a v-else class="ms-2">
    <i class="fa-solid fa-check"></i>
  </a>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
