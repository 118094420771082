<script setup>
import { ref, watch, onMounted } from "vue";
import ArgonSelectMulti from "@/components/multi/ArgonSelectMulti.vue";
import { influenceListStore } from "@/_store/influenceAreaStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ArgonLoadingButton } from "@/components";

const snackbar = ref(null);
const loading = ref(false);
const closeSnackbar = () => {
  snackbar.value = null;
};
const props = defineProps({
  areaInfluence: {
    type: Object,
    default() {
      return {};
    },
  }
})
const areaOfInfluence = ref(props.areaInfluence);

const influenceStore = influenceListStore();

function savePostalCodes() {
  loading.value = true;
  const dataToSend = JSON.parse(JSON.stringify(areaOfInfluence.value));
  influenceStore.sendInfluenceArea(dataToSend);
}

onMounted(() => {
  areaOfInfluence.value = props.areaInfluence;
});
watch(
  () => props.areaInfluence,
  (value) => {
    areaOfInfluence.value = value;
  }
);
watch(
  () => influenceStore.influence,
  (value) => {
    if (value) {
        snackbar.value = {
          title: "Guardado",
          message: "Ajustes guardados",
          type: "info",
          color: "white",
        };
        loading.value = false;
    }
  }
); watch(
  () => influenceStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);
</script>

<template>
  <div class="card h-100">
    <div class="card-header">Área de influencia</div>
    <div class="card-body">
      <div class="row me-2">
        <div class="col-6">
          <label for="col-form-label" class="col-form-label">NO realizaré la recogida a los clientes</label>
        </div>
        <div class="col-6">
          <argon-select-multi :id="'pickup_customer_codes'" v-model="areaOfInfluence.pickup_customer_codes" />
        </div>
      </div>
      <div class="row me-2">
        <div class="col-6">
          <label for="col-form-label" class="col-form-label">Códigos postales a los que recogeré</label>
        </div>
        <div class="col-6">
          <argon-select-multi :id="'pickup_postal_codes'" v-model="areaOfInfluence.pickup_postal_codes" />
        </div>
      </div>     
      <div class="row me-2">
        <div class="col-6">
          <label for="col-form-label" class="col-form-label">Códigos postales a los que entregaré</label>
        </div>
        <div class="col-6">
          <argon-select-multi :id="'delivery_postal_codes'" v-model="areaOfInfluence.delivery_postal_codes" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-end">
        <argon-loading-button class="btn btn-sm bg-gradient-primary m-0" color="primary" variant="gradient"
          :label="loading ? 'Guardando...' : 'Guardar'" :loading="loading"
          @click="savePostalCodes"></argon-loading-button>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
