<script setup>
import moment from "moment";
import { onMounted, watch, ref, computed,inject } from "vue";
import { useStore } from "vuex";
import { useUserStore } from "@/_store/userStore";
import { userTableStore } from "@/_store/userTableStore";
import ArgonButton from "@/components/ArgonButton.vue";
import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import NewUserModal from "./NewUserModal.vue";
import UserTypeBadge from "@/components/badges/UserType.vue";
const sweetAlert = inject("$swal");

const store = useStore();
var newUserModal = null;
const bootstrap = store.state.bootstrap;

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const userStore = useUserStore();
const userListStore = userTableStore();

const tableStore = argonTableStore();
const isAgency = computed(() => userStore.userSigned?.portalType==='AGENCY');

const fieldFilters = [
  {
    field: "user",
    description: "Usuario",
  },
];
const columns = ref([
  {
    header: "Creado",
    field: "createdAt",
    sort: false,
    width: "w-15",
  },
  {
    header: "Usuario",
    field: "user",
    sort: false,
    width: "w-15",
  },
  {
    header: "Activo",
    field: "status",
    sort: false,
    position: "center",
    width: "w-15",
  }
]);
const defaultFilters = ref([
  {
      field: "status",
      label: "Estado",
      multi: false,
      options: [
        { label: "Activo", value: "ACTIVED" },
        { label: "Inactivo", value: "INACTIVED" },
      ],
  }
]);

onMounted(() => {
  if (isAgency.value){
    loadAgencyConfigs();
  }
  tableStore.clear();
  tableStore.clearPagination();
  if (userStore.filtersPage.users)
    tableStore.changeFilters(userStore.filtersPage.users);
  else tableStore.changeFilters([]);
  reloadPage();
});
function loadAgencyConfigs() {
  const newHeader = {
    header: "Tipo",
    field: "roles",
    position: "center",
    sort: false,
    width: "w-15",
  }
  columns.value.push(newHeader);
  const newFilters =   {
      field: "roles",
      label: "Tipo",
      multi: false,
      options: [
        { label: "Agencia", value: "AGENCY" },
        { label: "Cliente", value: "CUSTOMER" },  
      ],
  }
  defaultFilters.value.push(newFilters);
}
function reloadPage() {
  userStore.filtersPage.users = tableStore.filters;
  userListStore.getUsers(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function activateUser() {
  userStore.activateUsers(tableStore.checkedList)
}
function deactivateUser() {
  userStore.deactivateUsers(tableStore.checkedList)
}
function forwardPassword() {
  sweetAlert({
    title: "¿Está seguro de restaurar la contraseña de los usuarios seleccionados?",
    text: "¡Si el usuario está activo recibirá un email con su nueva contraseña!",
    showCancelButton: true,
    confirmButtonText: "Restaurar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      userStore.forwardPassword(tableStore.checkedList)
    }
  });
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function newUser() {
    newUserModal = new bootstrap.Modal(document.getElementById("newUserModal"), {
    keyboard: true,
  });
  newUserModal.show();
}
function getType(roles){
  if (roles.includes("AGENCY"))
    return "AGENCIA"
  else if (roles.includes("CUSTOMER"))
    return "CLIENTE"
  else return ''
}
watch(
  () => userListStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => userStore.newedUser,
  (value) => {
    if (value) {
      newUserModal.hide();
      reloadPage();
    }
  }
);
watch(
  () => userStore.deactivatedUsers,
  (value) => {
    if (value) {
      tableStore.clearSelection();
      reloadPage()
      snackbar.value = {
        title: "Éxito",
        message: "Los usuarios seleccionados se han desactivado",
        type: "info",
        color: "white",
      };      
    }
  }
);
watch(
  () => userStore.activatedUsers,
  (value) => {
    if (value) {
      tableStore.clearSelection();
      reloadPage()
      snackbar.value = {
        title: "Éxito",
        message: "Los usuarios seleccionados se han activado",
        type: "info",
        color: "white",
      };
      
    }
  }
);
watch(
  () => userStore.forwadedPassword,
  (value) => {
    if (value) {
      tableStore.clearSelection();
      reloadPage()
      snackbar.value = {
        title: "Éxito",
        message: "Enviado email con nueva contraseña a los usuarios seleccionados",
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => userStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="userListStore.users"
        :show-config-option="false"
        :columns="columns"
        :field-filters="fieldFilters"
        :items="userListStore.users"
        :table-hover="false"
        :default-filters="defaultFilters"
        @page-changed="reloadPage"
        @on-reload="reloadPage"
        @filter-changed="reloadPage"
        @new-item="newUser"
      >
      <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              variant="outline"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-print me-sm-2"></i>Acciones</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="activateUser"
                  >Activar</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="deactivateUser"
                  >Desactivar</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="forwardPassword"
                  >Restaurar Contraseña</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td
            :position="getPosition('createdAt')"
            :text-primary="moment(item.createdAt).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.createdAt).fromNow()"
          ></argon-td>
          <argon-td
            :position="getPosition('user')"
            :text-secondary="item.user"
          ></argon-td>
          <argon-td :position="getPosition('status')">
            <i v-if="item.status && item.status=='ACTIVED'" class="fa fa-check text-xxs"></i>
          </argon-td>
          <argon-td 
            v-if="isAgency"
            :position="getPosition('roles')"
          >
            <user-type-badge
              class="badge badge-md"
              :item="getType(item.roles)"
            ></user-type-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
    <NewUserModal id="newUserModal"></NewUserModal>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>