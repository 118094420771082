<script setup>
import TimelineList from "@/components/tracking/ArgonTracking.vue";
import TimelineItem from "@/components/tracking/ArgonTrackingItem.vue";
import { onMounted } from "vue";
import { getStatusLabel } from "@/maestros/stopStatus";
import { dateUtils } from "@/utils";
import { stopListStore } from "@/_store";


const stopStore = stopListStore();

const props = defineProps({
  idStop: {
    type: String,
    default: "",
  }
});
onMounted(() => {
  if (props.idStop) stopStore.getStopTracking(props.idStop);
});

function getIcon(status) {
  const statusConfig = {
    PENDIENTE: { color: "info", component: "ni ni-pin-3" },
    EN_CURSO: { color: "warning", component: "fa fa-truck" },
    FINALIZADO: { color: "green", component: "ni ni-pin-3" },
    INCIDENCIA: { color: "danger", component: "fa fa-exclamation-triangle" },
    ANULADO: { color: "danger", component: "fa fa-ban" },
  };
  const defaultConfig = { color: "warning", component: "fa fa-truck" };
  return statusConfig[status] || defaultConfig;
}
</script>
<template>
  <div class="p-1">
    <div class="timeline-container" v-if="stopStore.stopTracking">
      <timeline-list>
        <timeline-item
          v-for="(item, key) in stopStore.stopTracking.data"
          :key="key"
          :icon="getIcon(item.status)"
          :title="getStatusLabel(item.status)"
          :name="item.description"
          :date-time="dateUtils.formatDate(item.date)"
        />
      </timeline-list>
    </div>
  </div>
</template>

<style scoped>
.timeline-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
