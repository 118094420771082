<script setup>
import ArgonHeader from "@/components/table/ArgonHeader.vue";
import { onMounted } from "vue";
import { computed, watch, ref } from "vue";
import { userStore } from "@/_store/userStore";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { apiKeyStore } from "@/_store/apikeyStore.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const apiStore = apiKeyStore();
const snackbar = ref(null);
const emit = defineEmits(["inputValues", "errorValidations"]);
const tableContainer = ref();
const userStores = userStore();
const genericTable = ref();

const props = defineProps({
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  showInputs: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Object,
    default() {
      return {
        total: 0,
        data: [],
      };
    },
  },
  headers: {
    type: Array,
    default() {
      return [];
    },
  },
  showPagination: {
    type: Boolean,
    default: false,
  },
});

let dataInputs = ref({
  appName: "",
});

const rules = {
  appName: {
    required: helpers.withMessage("Nombre es requerido", required),
  },
};
const v$ = useVuelidate(rules, dataInputs);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
function displaySpinner(show = false) {
  if (show) {
    let container = tableContainer.value;
    let table = genericTable.value;
    table.classList.add("overflow-hidden");
    container.classList.add("loading");
    container.style.setProperty("--size", "40px 40px");
    if (props.items.data.length <= 2) {
      container.style.setProperty("--top-position", "40%");
    } else {
      container.style.setProperty("--top-position", "0");
    }
    return;
  }

  let container = tableContainer.value;
  container.classList.remove("loading");

  let table = genericTable.value;
  table.classList.remove("overflow-hidden");
}
async function agregarApiKey() {
  const validation = await validate();
  const hasErrors = Object.keys(validation).length > 0;

  if (hasErrors) {
    let message = "";
    for (const value of validation) {
      message = `${message}<li>${value.$message}</li>`;
    }
    emit("errorValidations", message);
    return;
  }

  apiStore.newApiKeys(dataInputs.value);
  v$.value.$reset();
}

watch(
  () => apiStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => apiStore.send,
  (value) => {
    if (value) {
      emit("inputValues", dataInputs.value);
      dataInputs.value = {
        appName: "",
      };
      snackbar.value = {
        title: "Creado",
        message: "Se ha creado el api key.",
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => props.items,
  () => {
    displaySpinner();
  }
);

const closeSnackbar = () => {
  snackbar.value = null;
};

onMounted(() => {});
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
</script>
<template>
  <div class="row">
    <div class="col-4">
      <div class="d-flex align-items-center">
        <argon-input
          placeholder="Nombre"
          :error="v$.appName.$error"
          v-model="dataInputs.appName"
          class="flex-grow-1 me-2"
          max-length="25"
        />
        <argon-button
          @click="agregarApiKey"
          size="xs"
          color="primary"
          v-tooltip
          data-bs-title="Agregar"
          class="d-flex justify-content-center align-items-center mb-3"
          style="width: 10px; height: 20px"
        >
          +
        </argon-button>
      </div>
    </div>
  </div>
  <div class="p-0">
    <div
      id="simple-table-container-api"
      :class="isAgency ? 'agency' : 'customer'"
      class="col-md-auto loading"
      ref="tableContainer"
    >
      <div id="simple-table-api" ref="genericTable">
        <table class="table align-items-center mb-0">
          <thead class="sticky-top bg-white">
            <argon-header :headers="headers"></argon-header>
          </thead>
          <tbody style="z-index: 9999 !important">
            <tr v-for="item in items.data" :key="item._id">
              <slot :item="item"></slot>
            </tr>
          </tbody>
          <tfoot class="text-end">
            <slot name="foot-table"></slot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>

<style scoped>
.size-input {
  width: 100px;
}

.size-button {
  height: 20px !important;
  width: 20px !important;
  margin-top: 4px !important;
}
</style>
<style lang="scss" scoped>
.input-no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}

thead th {
  padding: 0px !important;
  border-bottom: none !important;
}

thead tr:nth-child(2) th {
  border-bottom: none !important;
  border-top: none !important;
}

.color {
  color: #8091c5 !important;
}

.overflow-hidden {
  overflow: hidden;
}
#simple-table-api {
  max-height: 250px;
  min-height: 250px;
  overflow-y: auto;
}
#simple-table-container-api.loading #simple-table {
  position: relative;
  border: none;
}

#simple-table-container-api.agency.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url("@/assets/img/loading-agency.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}

#simple-table-container-api.customer.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url("@/assets/img/loading-customer.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}

.card-detail-layout .table tfoot {
  border-color: #dee2e6 !important;
}
</style>
