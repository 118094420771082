<script setup>
import { ref, watch, onMounted } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from '@fullcalendar/moment';
import { ArgonLoadingButton } from "@/components";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import esLocale from "@fullcalendar/core/locales/es";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { accountConfList } from "@/_store/accountStopConfigStore";
import { dateUtils } from "@/utils";

const accountConfig = accountConfList();
const model = ref({
  closed_on_saturday: true,
  closed_on_sunday: true,
  holidays: []
});
const snackbar = ref(null);

const loading = ref(false);
const selectedDate = ref(null);

const calendarOptions = ref({
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
  initialView: "dayGridMonth",
  editable: true,
  selectable: true,
  selectMirror: true,
  timeZone: 'UTC',
  dayMaxEvents: true,
  weekends: true,
  select: handleDateSelect,
  eventClick: handleEventClick,
  locale: esLocale,
  headerToolbar: {
    left: 'prev',
    center: 'title',
    right: 'next'
  },
  titleFormat: 'MMMM YYYY',
  datesSet: () => {
    applyHolidayStyles(model.value.holidays);
    document.querySelectorAll('.fc-day').forEach(day => {
      day.addEventListener('mouseover', () => {
        day.style.cursor = 'pointer';
      });
    });
  },
  dayRender: function (info) {
    if (info.dateStr === selectedDate.value) {
      info.el.classList.add('selected-day');
    }
  },
});

function handleDateSelect(selectInfo) {
  const selectedDates = getDatesInRange(selectInfo.start, selectInfo.end);

  selectedDates.forEach((dateStr) => {
    const selectedDate = new Date(dateStr);
    const selectedYear = selectedDate.getFullYear();
    let holiday = model.value.holidays.find((h) => Number(h.year) === selectedYear);
    if (holiday) {
      const dateIndex = holiday.days.findIndex((d) => {
        const dFormatted = d.toISOString().split('T')[0];
        const selectedFormatted = selectedDate.toISOString().split('T')[0];
        return dFormatted === selectedFormatted;
      });

      if (dateIndex !== -1) {
        holiday.days.splice(dateIndex, 1);
        const formattedDate = selectedDate.toISOString().split('T')[0];
        const dayCell = document.querySelector(`.fc-day[data-date='${formattedDate}']`);
        if (dayCell) {
          dayCell.style.backgroundColor = '';
        }
      } else {
        holiday.days.push(selectedDate);
      }
    } else {
      holiday = {
        year: selectedYear,
        days: [selectedDate],
      };
      model.value.holidays.push(holiday);
    }
    const formattedDate = selectedDate.toISOString().split('T')[0];
    const dayCell = document.querySelector(`.fc-day[data-date='${formattedDate}']`);
    if (dayCell) {
      dayCell.style.backgroundColor = holiday.days.some(d =>
        d.toISOString().split('T')[0] === formattedDate
      ) ? '#0A9CFF' : '';
    }
  });
  applyHolidayStyles(model.value.holidays);
}

function applyHolidayStyles(holidays) {
  document.querySelectorAll('.fc-day').forEach(day => {
    day.style.backgroundColor = '';
  });
  if (holidays && Array.isArray(holidays)) {
    holidays.forEach(holiday => {
      const days = Array.isArray(holiday.days) ? holiday.days : holiday.days;
      if (Array.isArray(days)) {
        days.forEach(date => {
          const formattedDate = date.toISOString().split('T')[0];
          const dayCell = document.querySelector(`.fc-day[data-date='${formattedDate}']`);
          if (dayCell) {
            dayCell.style.backgroundColor = '#0A9CFF';
          }
        });
      }
    });
  }
}
function getDatesInRange(start, end) {
  let currentDate = new Date(start);
  const datesArray = [];
  while (currentDate < end) {
    datesArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesArray;
};

function handleEventClick(clickInfo) {
  if (
    confirm(
      `Are you sure you want to delete the event '${clickInfo.event.title}'`
    )
  ) {
    clickInfo.event.remove();
  }
}
onMounted(() => {
  accountConfig.getAccountStopConfig();
  let prevButton = document.querySelector('.fc-prev-button');
  let nextButton = document.querySelector('.fc-next-button');
  let headerToolbar = document.querySelector('.fc-header-toolbar');
  let iconLeft = document.querySelector('.fc-icon-chevron-left');
  let iconRight = document.querySelector('.fc-icon-chevron-right');

  if (prevButton && nextButton) {
    headerToolbar.style.marginBottom = "1px";
    headerToolbar.style.textTransform = "capitalize";
    iconLeft.style.color = "#0A9CFF";
    iconLeft.style.justifyContent = "center";
    iconLeft.style.textAlign = "center";
    iconLeft.style.marginTop = "2px";
    iconRight.style.color = "#0A9CFF";
    iconRight.style.justifyContent = "center";
    iconRight.style.textAlign = "center";
    iconRight.style.marginTop = "2px";

    prevButton = cleanClass(prevButton);
    nextButton = cleanClass(nextButton);
    prevButton.style.backgroundColor = 'white';
    prevButton.style.padding = "0";
    prevButton.style.marginLeft = "8px";
    prevButton.style.marginTop = "8px";
    prevButton.style.width = "35px";
    prevButton.style.height = "28px";
    prevButton.classList.remove('fc-button');
    prevButton.classList.add('btn', 'btn-sm');
    prevButton.classList.add('btn-outline-primary');

    nextButton.style.backgroundColor = 'white';
    nextButton.classList.remove('fc-button');
    nextButton.classList.add('btn', 'btn-sm');
    nextButton.classList.add('btn-outline-primary');
    nextButton.style.padding = "0";
    nextButton.style.marginRight = "8px";
    nextButton.style.marginTop = "8px";
    nextButton.style.width = "35px";
    nextButton.style.height = "28px";
  }
});
function cleanClass(element){
  for(const itemClass of element.classList){
    element.classList.remove(itemClass);
  }
  return element;
}
function save() {
  loading.value = true;
  validateEmptyArrays(model.value.holidays);
  const body = {
    closed_on_saturday: model.value.closed_on_saturday,
    closed_on_sunday: model.value.closed_on_sunday,
    holidays: formatDate(model.value.holidays),
  }
  if (model.value._id) accountConfig.putAccountStopConfig(model.value._id, body);
  else {
    body.cutoff_time = "";
    body.cutoff_time_exceptions = [];
    accountConfig.sendAccountStopConfig(body);
  }
}

function validateEmptyArrays(holidays) {
  const filteredHolidays = holidays.filter(holiday => holiday.days && holiday.days.length > 0);
  model.value.holidays = filteredHolidays;
}

function formatDate(holidays) {
  return (holidays || []).map(({ year, days }) => ({
    year,
    days: (days || []).map(date => dateUtils.add(date, "hours", 0)),
  }));
}

watch(
  () => accountConfig.sendConf,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
      loading.value = false;
    }
  }
);

watch(
  () => accountConfig.putConf,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
      loading.value = false;
    }
  }
);

watch(
  () => accountConfig.getConf,
  (value) => {
    if (value && Object.keys(value).length > 0) {
      model.value = {
        _id: value._id,
        closed_on_saturday: value.closed_on_saturday,
        closed_on_sunday: value.closed_on_sunday,
        holidays: value.holidays.map(holiday => ({
          year: holiday.year,
          days: holiday.days.map(day => new Date(day))
        })),
      };
      applyHolidayStyles(model.value.holidays);
    }
  }
);

watch(
  () => accountConfig.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);

const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card h-100">
    <div class="card-header">Festivos</div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <FullCalendar class="border calendar-container" :options="calendarOptions">
            <template v-slot:eventContent="arg">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
            </template>
          </FullCalendar>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <argon-checkbox id="flexCheckDefault" name="flexCheckDefault" v-model="model.closed_on_saturday">
                <label class="form-check-label" for="flexCheckDefault">
                  Cerramos en sábado
                </label>
              </argon-checkbox>
            </div>
          </div>
          <div class="col">
            <div class="col">
              <argon-checkbox id="flexCheckDefaultThunns" name="flexCheckDefaultThunns"
                v-model="model.closed_on_sunday">
                <label class="form-check-label" for="flexCheckDefaultThunns">
                  Cerramos en domingo
                </label>
              </argon-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-end">
        <argon-loading-button class="btn btn-sm bg-gradient-primary m-0"
          color="primary" variant="gradient" :label="loading ? 'Guardando...' : 'Guardar'" :loading="loading"
          @click="save"></argon-loading-button>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style scoped>
.selected-day {
  background-color: lightblue !important;
  color: black !important;
}

.size-button {
  height: 20px !important;
  width: 20px !important;
}

.calendar-container {
  width: 250px;
  height: 280px;
}
</style>