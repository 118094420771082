import { defineStore } from "pinia";
import { createAxios } from "@/_services/axiosHttp";

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export const dropzoneStore = defineStore("dropzone", {
  state: () => ({
    files: [],
    error: null,
    uploadStatus: null,
    disableButton: false,
  }),
  getters: {
    userSigned: (state) => {
      if (state.user == null) {
        let usr = localStorage.getItem("user");
        if (usr) {
          state.user = JSON.parse(usr);
        }
      }
      return state.user;
    },
    isAuthenticated: (state) => state.user != null,
  },
  actions: {
    addFiles(newFiles) {
      let newUploadableFiles = [...newFiles].map(
        (file) => new UploadableFile(file)
      );
      this.files = newUploadableFiles;
      newUploadableFiles.forEach((newFile) => {
        const existingFileIndex = this.files.findIndex(
          (file) => file.id === newFile.id
        );
        if (existingFileIndex !== -1) {
          this.files[existingFileIndex] = newFile;
        } else {
          this.files.push(newFile);
        }
      });
      this.files = [...this.files];
    },

    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },

    removeFile(file) {
      const index = this.files.indexOf(file);

      if (index > -1) this.files.splice(index, 1);
    },
    clearFiles() {
      this.files.splice(0, this.files.length);
      this.uploadStatus = null;
      this.disableButton = false;
    },
    uploadFile(file, url, extraData) {
      return new Promise((resolve, reject) => {
        // set up the request data
        let formData = new FormData();
        formData.append("file", file.file);

        if (extraData) {
          const data = JSON.stringify(extraData);
          formData.append("data", data);
        }

        // track status and upload file
        file.status = "loading";
        const axios = createAxios({ "Content-Type": "multipart/form-data" });
        axios
          .post(url, formData)
          .then((data) => {
            file.status = 200;
            resolve(data);
          })
          .catch((reason) => {
            file.status = reason.statusCode;
            reject(reason);
          });
      });
    },
    uploadFiles(url, extraData) {
      this.disableButton = true;
      Promise.all(
        this.files.map((file) => this.uploadFile(file, url, extraData))
      )
        .then(() => {
          this.uploadStatus = "SUCCESS";
          this.disableButton = false;
        })
        .catch((reason) => {
          this.uploadStatus = "ERROR";
          this.error = reason;
          this.disableButton = false;
        });
    },
  },
});
