<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonInput from "@/components/ArgonInput";
import ArgonCheckbox from "@/components/ArgonCheckbox";

let model = ref({
  refund_amount: 0,
  insurance: 0,
  delivery_on_saturday: false,
  returns: false
});

/* const tooltipText = ref(null); */

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      refund_amount: 0,
      insurance: 0,
      delivery_on_saturday: false,
      returns: false
    }),
  },
  inputsSize: {
    type: String,
    default: "default",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  serviceCourier: {
    type: String,
    default: "",
  },
  courier: {
    type: String,
    default: "",
  },
});

let key = ref(1);

onMounted(() => {
  model.value = props.modelValue;
  key.value += 1;
});
const keyCh=ref(0)
function getTooltipText() {
  if (props.readonly) return null;
  if (props.serviceCourier === 'CTT') return null;
  if (props.serviceCourier === 'CEX') return 'Dado el servicio no es posible marcar la prestación.';
  return null;
}
function getDisabledReturn(){
  if(props.readonly) return true;
  if(props.serviceCourier==='') return true;
  if(props.serviceCourier==='CEX') return true;
}
watch(
  () => props.serviceCourier,
  (newVal) => {
    if (newVal) {
      keyCh.value++;
      model.value.returns = false;
    }
  }
);
</script>

<template>
  <div role="form" class="p-1">
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Entrega sábado</label>
          </div>
          <div class="col-7">
            <argon-checkbox id="delivery_on_saturday" :key="key" v-model.number="model.delivery_on_saturday"
              name="delivery_on_saturday" class="ms-4" :disabled="readonly"></argon-checkbox>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Reembolso</label>
          </div>
          <div class="col-5">
            <argon-input id="refund_amount" v-model.number="model.refund_amount" type="number" placeholder=""
              class="ms-4" :disabled="readonly" />
          </div>
          <div class="col-1">&euro;</div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-3">
            <label class="col-form-label">Seguro</label>
          </div>
          <div class="col-5">
            <argon-input id="insurance" v-model.number="model.insurance" type="number" placeholder="" class="ms-4"
              :disabled="readonly" />
          </div>
          <div class="col-1">&euro;</div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-5">
            <label class="col-form-label">Retorno</label>
          </div>
          <div class="col-7">

            <argon-checkbox :key="keyCh" v-tooltip="getTooltipText()!=null" :data-bs-title="getTooltipText()" style="width: 20px;" id="returns" v-model.number="model.returns"
              name="returns" :disabled="getDisabledReturn()"></argon-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
