<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ref, watch, onMounted } from "vue";
import { accountConfList } from "@/_store/accountStopConfigStore";
import TableInputs from "@/components/table/TableInputs.vue";
import { ArgonLoadingButton } from "@/components";

const loading = ref(false);

const model = ref({
  cutoff_time: "",
  cutoff_time_exceptions: [],
});


onMounted(() => {
  accountConfig.getAccountStopConfig();
})

const snackbar = ref(null);
const listTable = ref({ data: [] });
const accountConfig = accountConfList();
const headers = [];

function eliminarExcepcion(item) {
  const index = listTable.value.data.indexOf(item);
  if (index > -1) listTable.value.data.splice(index, 1);
}

function errorValidations(message) {
  snackbar.value = {
    title: "Error",
    message: message,
    type: "danger",
    color: "white",
  };
}

function save() {
  loading.value = true;
  const body = {
    cutoff_time: model.value.cutoff_time,
    cutoff_time_exceptions: listTable.value.data
  }
  if (model.value._id) accountConfig.putAccountStopConfig(model.value._id, body);
  else {
    body.holidays = [];
    body.closed_on_saturday = true;
    body.closed_on_sunday = true;
    accountConfig.sendAccountStopConfig(body);
  }
}

function inputValues(data) {
  if (!Array.isArray(listTable.value.data)) {
    listTable.value.data = [];
  }
  listTable.value.data.push(data);
}

function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

const closeSnackbar = () => {
  snackbar.value = null;
};

watch(
  () => accountConfig.getConf,
  (value) => {
    if (value && Object.keys(value).length > 0) {
      model.value = {
        cutoff_time: value.cutoff_time,
        cutoff_time_exceptions: value.cutoff_time_exceptions,
        _id: value._id
      };
      listTable.value.data = value.cutoff_time_exceptions;
    }
  }
);

watch(
  () => accountConfig.sendConf,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
      accountConfig.getAccountStopConfig();
      loading.value = false;
    }
  }
);

watch(
  () => accountConfig.putConf,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Guardado",
        message: "Ajustes guardados",
        type: "info",
        color: "white",
      };
      loading.value = false;
    }
  }
);

watch(
  () => accountConfig.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);

</script>
<template>
  <div class="card h-100">
    <div class="card-header">Horas de cortes</div>
    <div class="card-body">

      <div class="row">
        <div class="col-6">
          <label for="col-form-label" class="col-form-label">
            Hora de corte
          </label>
        </div>
        <div class="col-6">
          <argon-input ref="initialFirstFrequency" v-model="model.cutoff_time"
            type="time" size="default" />
        </div>
      </div>
      <p></p>
      <div class="row">
        <div class="col">
          <label for="col-form-label" class="col-form-label">Excepciones por código postal</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table-inputs @inputValues="inputValues" @errorValidations="errorValidations" :showInputs="true"
            :headers="headers" :items="listTable"
            style="overflow-y: auto; overflow-x: hidden; min-height: 170px; max-height: 170px;">
            <template #default="{ item }">
              <argon-td :text-secondary="item.postal_code" :position="getPosition('postal_code')" />
              <argon-td :text-secondary="item.cutoff_time" :position="getPosition('cutoff_time')" />
              <argon-td>
                <a href="javascript:;" class="d-flex justify-content-center align-items-center" v-tooltip
                  data-bs-title="Borrar" @click="eliminarExcepcion(item)">
                  <i class="fas fa-trash text-xs"></i>
                </a>
              </argon-td>
            </template>
          </table-inputs>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-end">
        <argon-loading-button class="btn btn-sm bg-gradient-primary m-0"
          color="primary" variant="gradient" :label="loading ? 'Guardando...' : 'Guardar'" :loading="loading"
          @click="save"></argon-loading-button>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
