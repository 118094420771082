
<script setup>
import { watch } from 'vue';
import { fulfillmentStore } from "@/_store";

const fulfillStore = fulfillmentStore();

defineProps({
  packages: {
    type: Array,
    default() {
      return [];
    },
  },
});
function displayDocument(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function printBarcode(info){
  fulfillStore.generateBarcode(info.mac)
}
watch(
  () => fulfillStore.barcode,
  (newVal) => {
    if (newVal) {
      displayDocument(newVal);
    }
  }
);
</script>
<template>
  <div class="card h-100">
    <div class="card-header">Bultos</div>
    <div class="card-body body-height">
      <ul :class="`box-select-list list-group card-group custom-direction`">
        <li
          v-for="(
            packageInfo, index
          ) of packages" :key="index" :class="`border-0 list-group-item d-flex justify-content-between border-radius-lg p-1`">
          <div class="d-flex flex-column align-items-center w-100 overflow-hidden">
            <div class="w-100">
              <label class="text-size-xs text-dark">Bulto {{ packageInfo.order }}</label>
            </div>
            <div class="d-flex flex-row justify-content-between w-100 ms-2 mb-2">
              <div class="d-flex flex-column flex-grow-1">
                <div class="container-span d-flex justify-content-between">
                  <div class="mac-container">
                    <span
                      v-tooltip
                      :data-bs-title="packageInfo.mac"
                      class="text-size-xs text-truncate text-bold mac-size"
                    >
                    {{ packageInfo.mac }}
                    </span>
                  </div>
                  <button
                    v-tooltip
                    data-bs-title="Imprimir mac"
                    class="btn btn-link btn-icon-only btn-sm text-dark p-0 pb-2 mb-0"
                    @click="printBarcode(packageInfo)"
                  >
                    <i class="fa fa-print text-xs" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  class="container-span d-flex justify-content-between align-items-center"
                >
                  <span class="text-size-xs">{{ packageInfo.length }} x {{ packageInfo.width }} x {{ packageInfo.height }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="position-absolute top-0 end-0">
              <button
                v-tooltip
                data-bs-title="Imprimir mac"
                class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark"
                @click="printBarcode(packageInfo.mac)"
              >
                <i class="fa fa-print text-xs" aria-hidden="true"></i>
              </button>
            </div>
            <div class="d-flex flex-row justify-content-between w-100">
              <span class="text-sm text-dark">Bulto {{ packageInfo.order }}</span>
            </div>
            <div class="d-flex flex-row justify-content-between w-100">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row text-xs">
                  {{ packageInfo.mac }}
                </div>
                <span class="d-flex flex-row text-center text-xs">
                  {{ packageInfo.length }} x {{ packageInfo.width }} x {{ packageInfo.height }}
                </span>
              </div>
            </div> -->
          </div>
        </li>
      </ul>
      <div v-if="packages.length == 0" class="row my-3">
        
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.card-detail-layout .text-size-xs {
  font-size: 0.75rem !important;
}
.box-select-list {
  overflow: auto
}

.custom-direction {
  flex-direction: unset;
}
.container-span {
  height: 20px;
}
.center-ul {
  padding: 0px;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.mac-size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.mac-container {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
}
.body-height{
  max-height: 485px;
  overflow-y: auto;
}
</style>
