<script setup>
import TimelineList from "@/components/tracking/ArgonTracking.vue";
import TimelineItem from "@/components/tracking/ArgonTrackingItem.vue";
import { onMounted } from "vue";
import { shipmentListStore } from "@/_store/shipmentStore";
import { getStatusLabel } from "@/maestros/shipmentStatus";
import { dateUtils } from "@/utils";

const shipmentStore = shipmentListStore();
const props = defineProps({
  idShipment: {
    type: String,
    default: "",
  },
  url: {
    type: String,
    default: "",
  },
  courier: {
    type: String,
    default: "",
  },
});
onMounted(() => {
  if (props.idShipment) shipmentStore.getShipmentTracking(props.idShipment);
});

function getIcon(status) {
  const statusConfig = {
    RECOGIDA_PENDIENTE: { color: "info", component: "ni ni-pin-3" },
    RECOGIDO: { color: "info", component: "fa fa-truck" },
    EN_TRANSITO: { color: "warning", component: "fa fa-truck" },
    EN_REPARTO: { color: "warning", component: "fa fa-truck" },
    ENTREGADO: { color: "green", component: "ni ni-pin-3" },
    FINALIZADO: { color: "green", component: "ni ni-pin-3" },
    ENTREGA_CANCELADA: { color: "green", component: "ni ni-pin-3" },
    INCIDENCIA: { color: "danger", component: "fa fa-exclamation-triangle" },
    ANULADO: { color: "danger", component: "fa fa-ban" },
  };
  const defaultConfig = { color: "warning", component: "fa fa-truck" };
  return statusConfig[status] || defaultConfig;
}
</script>
<template>
  <div class="p-1">
    <div v-if="url != ''" class="row">
      <span>
        <a :href="url" class="link-primary" target="_blank"
          >Pulse aquí, para ver el seguimiento de su envío en la página
          de: {{ courier }}</a
        >
      </span>
    </div>
    <timeline-list v-if="shipmentStore.shipmentTracking">
      <timeline-item
        v-for="(item, key) in shipmentStore.shipmentTracking.data"
        :key="key"
        :icon="getIcon(item.status)"
        :title="getStatusLabel(item.status)"
        :name="item.description"
        :date-time="dateUtils.formatDate(item.date)"
      />
    </timeline-list>
  </div>
</template>