<script setup>
import { onMounted, watch, ref } from "vue";
import Choices from "choices.js";

function isOutOfViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  const out = {};

  out.right =
    bounding.right >
    (window.innerWidth || document.documentElement.clientWidth);

  return out;
}

function adjustDropdownPosition() {
  let menus = document.querySelectorAll(".choices__list--dropdown");
  menus.forEach((menu) => {
    const out = isOutOfViewport(menu);

    if (out.right) {
      menu.style.right = "0";
      menu.style.left = "auto";
    } else {
      menu.style.right = "auto";
      menu.style.left = "0";
    }
  });
}

function onClickSelect() {
  adjustDropdownPosition();
}

const emit = defineEmits(["update:modelValue", "selectedChange"]);
const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Array, String, Number],
    default: "",
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  placeholder: {
    type: String,
    default: "",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  nameValue: {
    type: String,
    default: "value",
  },
  nameLabel: {
    type: String,
    default: "label",
  },
  description: {
    type: String,
    default: "description",
  },
  courier: {
    type: String,
    default: "courier",
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  separator: {
    type: String,
    default: "-",
  },
  menuWidth: {
    type: String,
    default: "300",
  }
});

let localValue = ref(props.modelValue);
const fileFormatChoices = ref(null);

onMounted(() => {
  if (document.getElementById(props.id)) {
    const listLabel = props.nameLabel.split(",");
    const element = document.getElementById(props.id);
    const formatOptions = props.options.map((option) => ({
      value: option[props.nameValue],
      label: listLabel.map((label) => option[label]).join(" - "),
      customProperties: {
        description: option[props.description],
        image: option.image,
      },
    }));
    let multipleOptions = {};
    if (props.multiple) {
      multipleOptions = {
        removeItemButton: true,
      };
    }
    let formClass = `form-control form-control-${props.size}`;
    fileFormatChoices.value = new Choices(element, {
      position: 'bottom',
      shouldFitContainer: true,
      placeholderValue: props.placeholder,
      searchEnabled: props.filterable,
      removeItemButton: true,
      shouldSort: false,
      ...multipleOptions,
      itemSelectText: "",
      noResultsText: "No resultados encontrados",
      noChoicesText: "No hay opciones para elegir",
      classNames: {
        containerInner: formClass,
      },
      callbackOnCreateTemplates: function (template) {
        return {
          item: (classNames, data) => {
            let removeButtonHtml="";
            if(props.multiple){
              removeButtonHtml = `<button type="button" class="${classNames.classNames.button}" aria-label="Remove item: '${data.label}'" data-button>Remove item</button>`
            }else if(props.clearable){
              removeButtonHtml=`<button type="button" class="${classNames.classNames.button}" style="opacity: 0;" aria-label="Remove item: '${data.label}'" data-button>Remove item</button>`;
            }
              const image = data.customProperties.image
              ? `<img src="${data.customProperties.image}" style="margin-right:5px;width: 16px; height: 16px; border-radius: 50%; object-fit: cover;" />`
              : "";

              return template(`
                <div class="${classNames.classNames.item} ${
                data.highlighted
                  ? classNames.classNames.highlightedState
                  : classNames.classNames.itemSelectable
              }" data-item data-id="${data.id}" data-value="${
                data.value
              }" data-deletable>
                  ${image}
                  ${data.label}
                  ${removeButtonHtml}
                </div>
            `);
          },
          choice: (classNames, data) => {
            const image = data.customProperties.image
              ? `<img src="${data.customProperties.image}" style="margin-right:5px; width: 16px; height: 16px; border-radius: 50%; object-fit: cover;" />`
              : "";

            return template(`
        <div class="${classNames.classNames.item} ${
              classNames.classNames.itemChoice
            } ${
              data.highlighted ? classNames.classNames.highlightedState : ""
            } ${
              data.disabled
                ? classNames.classNames.itemDisabled
                : classNames.classNames.itemSelectable
            }" data-select-text="${
              this.config.itemSelectText
            }" data-choice data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            } title="${
              data.customProperties.description
                ? data.customProperties.description
                : ""
            }">
          ${image}
          <span class="choice-label">${data.label}</span>
        </div>
      `);
          },
        };
      },
    });
    if (!fileFormatChoices.value?.containerOuter) return;
    fileFormatChoices.value.setChoices(formatOptions, "value", "label", false);
    loadValue(props.modelValue);
    let menus = document.getElementsByClassName("choices__list--dropdown");
    Array.from(menus).forEach((menu) => {
      menu.style.minWidth = `${props.menuWidth}px`;
      menu.style.top = "0px"
    });
  }
});

const updateValidityClass = (error) => {
  const container = fileFormatChoices.value?.containerOuter.element;

  if (error) {
    container.classList.add("is-invalid");
    container.classList.remove("is-valid");
  } else {
    container.classList.add("is-valid");
    container.classList.remove("is-invalid");
  }
};
const loadValue = (newValue) => {
  if (Array.isArray(newValue) && props.multiple) {
    newValue.forEach((value) => {
      const selected = props.options.find((option) => {
        return option[props.nameValue] == value;
      });
      if (selected) {
        fileFormatChoices.value.setChoiceByValue(selected[props.nameValue]);
      }
    });
  } else {
    const selected = props.options.find((option) => {
      return option[props.nameValue] == newValue;
    });
    if (selected) {
      fileFormatChoices.value.setChoiceByValue(selected[props.nameValue]);
    }
  }
  localValue.value = newValue;
};
const getClasses = (size, success, error) => {
  let sizeValue = size ? `form-select-${size}` : null;
  let isValidValue = error ? "is-invalid" : success ? "is-valid" : "";
  return `${sizeValue} ${isValidValue}`;
};

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);

watch(
  () => props.error,
  (newVal) => {
    if (fileFormatChoices.value) {
      updateValidityClass(newVal);
    }
  },
  { immediate: true }
);
watch(
  () => props.modelValue,
  (newVal) => {
    if (fileFormatChoices.value) {
      loadValue(newVal);
    }
  }
);
</script>
<template>
  <div @click="onClickSelect" class="form-group">
    <p
      v-if="label != ''"
      class="fs-6 fw-bold"
      :class="{ required: isRequired }"
    >
      {{ label }}
    </p>
    <p
      v-if="
        !localValue || (Array.isArray(localValue) && localValue.length === 0)
      "
      class="placeholder text-sm position-absolute"
    >
      {{ placeholder }}
    </p>
    <select
      :id="id"
      v-model="localValue"
      :name="name"
      :required="isRequired"
      class="form-select"
      :class="getClasses(size, success, error)"
      :disabled="disabled"
      :multiple="multiple"
      @change="emit('selectedChange', localValue)"
    ></select>
  </div>
</template>
<style>
.custom-close-button {
  bottom: 3px;
  left: 45px;
  font-size: 8px;
  width: 10px;
  height: 20px;
  border: none;
  cursor: pointer;
}
</style>
<style scoped>
.form-group {
  position: relative;
}

.placeholder {
  color: #b2b9c1 !important;
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: default;
  background-color: transparent;
  opacity: 0.7;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

.form-select {
  padding-top: 1.5em;
}

.form-select:focus ~ .placeholder,
.form-select:not(:placeholder-shown) ~ .placeholder {
  opacity: 0;
}

</style>
