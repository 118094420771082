<!-- eslint-disable no-unused-vars -->
<script setup>
import { objectUtils } from "@/utils";
import { ProductList } from "./products";
import { FulfillmentPackage } from "./package";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import Service from "./Service.vue";
import Perks from "./Perks.vue";
import Contact from "./Contact.vue";
import Schedules from "./Schedules.vue";
import Direction from "./Direction.vue";
import { PackageList } from "./packages";
import { ProductReader } from "./product-reader";
import { fulfillmentStore } from "@/_store";
import { shipmentDetailStore } from "@/_store";

import { onMounted, watch, inject, ref, computed } from "vue";

const shipmentStore = shipmentDetailStore();
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      packages: [],
      service: "",
      pickup_contact: {
        name: "",
        vat_number: "",
        email: "",
        attention_of: "",
        phone: "",
      },
      features: {
        refund_amount: 0,
        insurance: 0,
        delivery_on_saturday: false,
        returns: false,
      },
      pickup_address: {
        postal_code: "",
        street: "",
        street2: "",
        city: "",
        country: "ES",
        location: {
          lat: 0,
          lng: 0,
        },
        text: "",
        place_id: "",
        normalized: true,
        province: "",
      },
      pickup_schedules: {
        first_frequency: {
          initial: "",
          final: "",
        },
        second_frequency: {
          initial: "",
          final: "",
        },
      },
    }),
  },
  order: {
    type: Object,
    default() {
      return {};
    },
  },
  shop: {
    type: Object,
    default() {
      return {};
    },
  },
});
const tabs = ref([
  { label: "Servicios", value: "services" },
  { label: "Recogida", value: "pickup" },
  { label: "Prestaciones", value: "perks" },
]);
let disabledReturn = ref(false);
const currentTab = ref("services");
const model = ref({
  packages: [],
  service: "",
  pickup_contact: {
    name: "",
    vat_number: "",
    email: "",
    attention_of: "",
    phone: "",
  },
  features: {
    refund_amount: 0,
    insurance: 0,
    delivery_on_saturday: false,
    returns: false,
  },
  pickup_address: {
    postal_code: "",
    street: "",
    street2: "",
    city: "",
    country: "ES",
    location: {
      lat: 0,
      lng: 0,
    },
    text: "",
    place_id: "",
    normalized: true,
    province: "",
  },
  pickup_schedules: {
    first_frequency: {
      initial: "",
      final: "",
    },
    second_frequency: {
      initial: "",
      final: "",
    },
  },
});

const fulfillmentPackage = ref({
  items: [],
  mac: "",
  order: 1,
  length: 0,
  width: 0,
  height: 0,
  weight: 0,
});

const store = fulfillmentStore();
const defaultPackage = ref(null);

const products = ref([]);
let fulfillmentDirection = ref(null);
let fulfillmentService = ref(null);
let fulfillmentContact = ref(null);

let key = ref(1);

const defaultData = {
  items: [],
  order: 1,
  length: 0,
  width: 0,
  height: 0,
};

onMounted(() => {
  model.value = props.modelValue;
  document
    .querySelector("#fulfillmentModal")
    .addEventListener("hidden.bs.modal", (ev) => {
      resetModel();
      products.value = [];
    });
  document
    .querySelector("#fulfillmentModal")
    .addEventListener("show.bs.modal", (ev) => {
      model.value = props.modelValue;
      getContactPickupByDefect();
    });
});

watch(
  () => props.order,
  (value) => {
    if (Object.keys(value).length > 0) {
      // for(let x=0; x<50;x++) {
      //   // products.value = objectUtils.copyByValue(props.order.products)
      //   products.value.push(props.order.products[0])
      // }
      products.value = objectUtils.copyByValue(props.order.products);
      resetModel();
    }
  }
);

function resetModel() {
  fulfillmentPackage.value = objectUtils.copyByValue({ ...defaultData });
  copyPackages.value = [];
  currentTab.value = "services";
}
function getContactPickupByDefect() {
  shipmentStore.getContactByDefault();
}

function addAllProduct() {
  products.value.forEach((product) => {
    if (Number(product["quantity_pending"]) == 0) return;

    const currentValue = Number(product["quantity_pending"]);

    product["quantity_pending"] = 0;

    const findIndex = (element) => element.id == product.id;
    const index = fulfillmentPackage.value.items.findIndex(findIndex);

    if (index >= 0) {
      const productFulfillmentCurrentValue =
        fulfillmentPackage.value.items[index]["quantity"];
      fulfillmentPackage.value.items[index]["quantity"] =
        currentValue + productFulfillmentCurrentValue;
      return;
    } else {
      const newProduct = { ...product };
      newProduct["quantity"] = currentValue;
      fulfillmentPackage.value.items.push({ ...newProduct });
    }
  });
}

function addSingleAllProduct(item) {
  const findIndex = (element) => element.id == item.id;
  const index = products.value.findIndex(findIndex);

  if (index < 0) return;

  const quantityPending = Number(products.value[index]["quantity_pending"]);

  if (quantityPending <= 0) return;

  products.value[index]["quantity_pending"] = 0;

  addAllSingleFulFillmentProductQuantity(item, quantityPending);
}

function addAllSingleFulFillmentProductQuantity(item, quantityToAdd) {
  const findIndex = (element) => element.id == item.id;
  const index = fulfillmentPackage.value.items.findIndex(findIndex);

  if (index < 0) {
    const product = { ...item, quantity: quantityToAdd };
    fulfillmentPackage.value.items.push(product);
  } else {
    const currentValue = Number(
      fulfillmentPackage.value.items[index]["quantity"]
    );
    fulfillmentPackage.value.items[index]["quantity"] =
      currentValue + quantityToAdd;
  }
}

function addProduct(item) {
  const findIndex = (element) => element.id == item.id;
  const index = products.value.findIndex(findIndex);

  if (index < 0) return;

  const currentValue = Number(products.value[index]["quantity_pending"]);
  const nextValue = currentValue - 1;

  if (nextValue < 0) return;

  products.value[index]["quantity_pending"] = nextValue;

  updateFulFillmentProduct(item);
}

function updateFulFillmentProduct(item) {
  const findIndex = (element) => element.id == item.id;
  const index = fulfillmentPackage.value.items.findIndex(findIndex);

  if (index < 0) {
    const product = { ...item, quantity: 1 };
    fulfillmentPackage.value.items.push(product);
  } else {
    const currentValue = Number(
      fulfillmentPackage.value.items[index]["quantity"]
    );
    fulfillmentPackage.value.items[index]["quantity"] = currentValue + 1;
  }
}

function removeAllProducts(item) {
  const findIndex = (element) => element.id == item.id;
  const index = fulfillmentPackage.value.items.findIndex(findIndex);
  if (index < 0) return;
  let currentValue = Number(fulfillmentPackage.value.items[index]["quantity"]);
  products.value[index]["quantity_pending"] =
    currentValue + products.value[index]["quantity_pending"];
  fulfillmentPackage.value.items[index]["quantity"] = 0;
}

function removeProduct(item) {
  const findIndex = (element) => element.id == item.id;

  const index = fulfillmentPackage.value.items.findIndex(findIndex);
  if (index < 0) return;

  const currentValue = Number(
    fulfillmentPackage.value.items[index]["quantity"]
  );
  const nextValue = currentValue - 1;
  if (nextValue <= 0) {
    fulfillmentPackage.value.items = fulfillmentPackage.value.items.filter(
      (product) => product.id != item.id
    );
    removeFulFillmentProductQuantity(item);
    return;
  }

  fulfillmentPackage.value.items[index]["quantity"] = nextValue;

  removeFulFillmentProductQuantity(item);
}

function removeFulFillmentProductQuantity(item) {
  const findIndex = (element) => element.id == item.id;

  const index = products.value.findIndex(findIndex);
  if (index < 0) return;

  const currentValue = Number(products.value[index]["quantity_pending"]);
  products.value[index]["quantity_pending"] = currentValue + 1;
}
const copyPackages = ref([]);
function addPackage(fulfillmentBoxFields) {
  // for(let x=0; x<10;x++) {
  //   packages.value.push({
  //     ...fulfillmentPackage.value,
  //     ...fulfillmentBoxFields
  //   })
  // }
  copyPackages.value.push({
    ...fulfillmentPackage.value,
    ...fulfillmentBoxFields,
  });
  model.value.packages = copyPackages.value;
  resetFulfillmentPackage(fulfillmentPackage.value);
  /* model.value.packages.push({
    ...fulfillmentPackage.value,
    ...fulfillmentBoxFields
  }) */
}

function resetFulfillmentPackage(currentBox) {
  const sortedProducts = objectUtils.copyByValue(model.value.packages);
  sortedProducts.sort((a, b) => a.order - b.order);
  if (sortedProducts.length == 0) {
    fulfillmentPackage.value = {
      order: currentBox.order + 1,
      items: [],
      length: defaultPackage.value.length,
      width: defaultPackage.value.width,
      height: defaultPackage.value.height,
    };
    return;
  }
  const lastpackageNumber = sortedProducts[sortedProducts.length - 1]["order"];
  fulfillmentPackage.value = {
    order: lastpackageNumber + 1,
    items: [],
    length: defaultPackage.value.length,
    width: defaultPackage.value.width,
    height: defaultPackage.value.height,
  };
}

function selectedContact(value) {
  model.value.pickup_contact = objectUtils.deepCopy(
    model.value.pickup_contact,
    value.contact
  );
  model.value.pickup_address = objectUtils.deepCopy(
    model.value.pickup_address,
    value.address
  );
  model.value.pickup_schedules = objectUtils.deepCopy(
    model.value.pickup_schedules,
    value.schedules
  );
  keyDefault.value++;
}
async function validateDirection() {
  return await fulfillmentDirection.value.validate();
}

async function validateService() {
  return await fulfillmentService.value.validate();
}

async function validateContact() {
  return await fulfillmentContact.value.validate();
}

async function validateFulFillment() {
  const promiseDirection = validateDirection();
  const promiseService = validateService();
  const prommiseContact = validateContact();
  Promise.all([validateDirection(), validateService(), validateContact()]).then(
    ([directionValid, serviceValid, contactValid]) => {
      if (serviceValid.length > 0) {
        currentTab.value = "services";
      } else if (directionValid.length > 0) {
        currentTab.value = "pickup";
      } else if (contactValid.length > 0) {
        currentTab.value = "pickup";
      }
    }
  );

  return [promiseDirection, promiseService, prommiseContact];
}

defineExpose({
  validateFulFillment,
  products,
});

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      model.value = props.modelValue;
    }
  }
);

/* watch(
  () => store.barcode,
  (newVal) => {
    if (newVal) {
      model.value.packages = copyPackages.value;
      resetFulfillmentPackage(fulfillmentPackage.value);
    }
  }
); */

watch(
  () => props.shop,
  (value) => {
    if (value) {
      const { default_package } = value;
      if (!default_package) return;
      defaultPackage.value = { ...default_package };
      fulfillmentPackage.value = {
        ...fulfillmentPackage.value,
        length: default_package.length,
        width: default_package.width,
        height: default_package.height,
        weight: 0,
      };
    }
  }
);
const keyDefault = ref(0);

function isEmptyObject(value) {
  return typeof value === "object" && Object.keys(value).length === 0;
}
watch(
  () => shipmentStore.defaultContact,
  (value) => {
    if (!value || isEmptyObject(value)) return null;
    model.value.pickup_contact = objectUtils.deepCopy(
      model.value.pickup_contact,
      value.contact
    );
    model.value.pickup_address = objectUtils.deepCopy(
      model.value.pickup_address,
      value.address
    );
    model.value.pickup_schedules = objectUtils.deepCopy(
      model.value.pickup_schedules,
      value.schedules
    );
    keyDefault.value++;
  }
);
watch(
  () => model.value.service,
  (value) => {
    if (value) {
      const foundService = shipmentStore.services?.find(
        (service) => service.name === value
      );
      if (foundService && foundService.courier) {
        if (foundService.courier === "CEX") {
          disabledReturn.value = true;
        } else {
          disabledReturn.value = false;
        }
      } else {
        disabledReturn.value = false;
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="row h-100">
    <div class="col-sm-12 col-md-2 col-lg-2">
      <product-list
        :products="products"
        :order-status="props.order.status"
        @add-all-product="addAllProduct"
        @add-product="addProduct"
        @add-single-all-product="addSingleAllProduct"
      />
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8">
      <div class="row mb-2 spacing">
        <div class="col-sm-12 col-md-12 col-lg-12 p-0">
          <ProductReader
            :order="fulfillmentPackage.order"
            :products="products"
            @add-product="addProduct"
          />
        </div>
      </div>
      <div class="row mb-2 spacing">
        <div class="col-sm-12 col-md-12 col-lg-12 p-0">
          <FulfillmentPackage
            v-model="fulfillmentPackage"
            :order="order"
            :shop="shop"
            @add-package="addPackage"
            @remove-product="removeProduct"
            @remove-all-products="removeAllProducts"
          />
        </div>
      </div>
      <div class="row mb-2 spacing">
        <div class="card">
          <div class="card-body">
            <tabs-bootstrap v-model="currentTab" :tabs="tabs" />
            <div class="mb-2 p-2" style="min-height: 180px">
              <div v-show="currentTab === 'services'" class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 p-0 pe-2">
                  <Service
                    ref="fulfillmentService"
                    v-model="model"
                    :divorder="order"
                    :shop="shop"
                  />
                </div>
              </div>
              <div v-show="currentTab === 'perks'" class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 p-0 pe-2">
                  <Perks
                    :disabled-return="disabledReturn"
                    v-model="model.features"
                  />
                </div>
              </div>
              <div v-show="currentTab === 'pickup'" class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 p-0 mb-2">
                  <Contact
                    :key="keyDefault"
                    ref="fulfillmentContact"
                    v-model="model.pickup_contact"
                    @selectedContact="selectedContact"
                  />
                </div>
                <div class="col-sm-12 p-0 pe-2">
                  <Direction
                    :key="keyDefault"
                    ref="fulfillmentDirection"
                    v-model="model.pickup_address"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 p-0">
                  <Schedules
                    :key="keyDefault"
                    v-model="model.pickup_schedules"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2">
      <PackageList :packages="model.packages" @remove-box="removeProduct" />
    </div>
  </div>
</template>
<style lang="scss">
</style>