<!-- eslint-disable no-unused-vars -->
<script setup>
import { ArgonLoadingButton } from "@/components"
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import Ribbon from "@/components/Ribbon.vue";
import { objectUtils } from "@/utils";
import { orderListStore } from "@/_store/ordersStore";
import { fulfillmentStore as fulfillmentOrderStore } from "@/_store";
import { FulfillmentContainer } from './fulfillment'
import { argonTableStore } from "@/_store/argonTableStore";
import * as stores from "@/_store";
import { dateUtils } from "@/utils";
import { onMounted, watch, provide, ref, computed,inject } from "vue";
const sweetAlert = inject("$swal");

const emit = defineEmits(["hide"]);

const closeSnackbar = () => {
  snackbar.value = null;
};
const fulfillmentModalElement = ref(null);
const marketplaceStore = stores.marketplaceStore();
const fulfillmentStore = fulfillmentOrderStore();
const snackbar = ref(null);
const loading = ref(false);
const ordersStore = orderListStore();
const tableStore = argonTableStore();
const fulfillmentForm = ref(null);
const shop = ref({})
const orders = ref([])
const selectedOrders = ref([])
const currentOrder = ref({})
const currentIndex = ref(0)
const orderStore = orderListStore();
const defaultData = {
  packages: [],
  service: "",
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  pickup_contact: {
    name: "",
    vat_number: "",
    email: "",
    attention_of: "",
    phone: "",
  },
  features: {
    refund_amount: 0,
    insurance: 0,
    delivery_on_saturday: false,
    returns: false
  },
  pickup_address: {
    postal_code: "",
    street: "",
    street2: "",
    city: "",
    country: "ES",
    location: {
      lat: 0,
      lng: 0,
    },
    text: "",
    place_id: "",
    normalized: true,
    province: "",
  },
  pickup_schedules: {
    first_frequency: {
      initial: "",
      final: "",
    },
    second_frequency: {
      initial: "",
      final: "",
    },
  },
}

let fulfillmentDirectionForm = ref(null);
const disableButton = ref(false);
const countries = ref([
  "ES", "PT", "GI", "AD"
])

const fulfillment = ref(objectUtils.copyByValue({ ...defaultData }))
const isNotAllProductPrepared = computed({
  get() {
    return fulfillmentForm.value.products.some((product) => product.quantity_pending > 0)
  }
});
onMounted(() => {
  document.querySelector("#fulfillmentModal").addEventListener("show.bs.modal", (ev) => {
    initializeOrders();
  });

});

async function validateDirection() {
  return await fulfillmentDirectionForm.value.validate();
}

function getShop() {
  loading.value=true;
  const { shop_id } = currentOrder.value;
  marketplaceStore.getShop(shop_id);
}

function warningBeforeSave(){
  if (fulfillment.value.packages.length == 0) {
    snackbar.value = {
      title: "Error",
      message: `<ul>Sin bultos preparados</ul>`,
      type: "danger",
      color: "white",
    };
    return
  }
  if (isNotAllProductPrepared.value){
    sweetAlert({
      title: "¿Está seguro de continuar?",
      text: "No has preparado todos los artículos",
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      customClass: {
        confirmButton: `btn ms-1 bg-gradient-primary`,
        cancelButton: "btn btn-outline-primary me-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        saveFulFillment()
      } else {
        return
      }
    });
    return;
  }
  saveFulFillment()
}
async function saveFulFillment() {
  
  const formPromise = await fulfillmentForm.value.validateFulFillment()
  const form = await formPromise

  Promise.all(form).then(
    (values) => {
      let message = "";
      for (const value of values) {
        for (const error of value) {
          message = `${message}<li>${error.$message}</li>`;
        }
      }
      if (message != "") {
        snackbar.value = {
          title: "Error",
          message: `<ul>${message}</ul>`,
          type: "danger",
          color: "white",
        };
      } else {
        disableButton.value = true
        fulfillmentStore.fulfillOrder({
          ...fulfillment.value,
          marketplace: currentOrder.value["marketplace"],
          customer_id: currentOrder.value["customer_id"],
          external_order_id: currentOrder.value["order_id"],
        }, currentOrder.value["_id"])
      }
    }
  );
}

function nextOrder() {
  const nextIndex = (currentIndex.value + 1) % tableStore.checkedList.length;
  currentIndex.value = nextIndex;
  orderStore.getOrderById(tableStore.checkedList[currentIndex.value]);
  resetModel();
}

function previousOrder() {
  const prevIndex = (currentIndex.value - 1 + tableStore.checkedList.length) % tableStore.checkedList.length;
  currentIndex.value = prevIndex;  // Actualiza el índice
  orderStore.getOrderById(tableStore.checkedList[currentIndex.value]);
  
  resetModel();
  getShop();
}

function resetModal() {
  selectedOrders.value = []
  currentOrder.value = {}
  currentIndex.value = 0
  resetModel()
  emit("hide");
}

function initializeOrders() {
  currentIndex.value = 0;
  orderStore.getOrderById(tableStore.checkedList[currentIndex.value]);
}

function resetModel() {
  fulfillment.value = objectUtils.copyByValue({ ...defaultData })
}
function setShop() {
  if (Object.keys(shop.value) == 0) return

  const { default_package } = shop.value

  if (!default_package) return

  const { customer } = currentOrder.value
  if(!customer) return;
  const { address: { country } } = customer

  const { national_service, international_service } = shop.value
  if (countries.value.includes(country))  {
    fulfillment.value.service = national_service
  }
  else {
    fulfillment.value.service = international_service
  }
  fulfillmentStore.changeService++;
}

watch(
  () => shop.value,
  (value) => {
    if (value) {
      setShop()
    }
  }
);

watch(
  () => orderStore.orderId,
  (value) => {
    if (value) {
      currentOrder.value = value;
      getShop();
    }
  }
);

watch(
  () => marketplaceStore.shop,
  (value) => {
    if (value) {
      shop.value = value;
      loading.value=false;
    }
  }
);
watch(
  () => marketplaceStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value=false;
    }
  }
);

watch(
  () => ordersStore.orders,
  (newVal) => {
    if (newVal) {
      orders.value = objectUtils.copyByValue(newVal['data']);
    }
  }
);

watch(
  () => fulfillmentStore.fulfillOrderState,
  (value) => {
    if (value) {
      disableButton.value = false
      if(value.status!="PREPARADO"){
        snackbar.value = {
          title: "Actualizado",
          message: `<ul>Pedido preparado</ul>`,
          type: "info",
          color: "white",
        };
        orderStore.getOrderById(currentOrder.value._id);
      }
      currentOrder.value.status=value.status;
      fulfillment.value.packages.length = 0;
    }
  }
);

watch(
  () => fulfillmentStore.error,
  (value) => {
    if (value) {
      disableButton.value = false
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

const orderName = computed({
  get() {
    if (!currentOrder.value || !currentOrder.value['number']) return ""

    return `Pedido #${currentOrder.value['number']}`
  }
});
</script>

<template>
  <div ref="fulfillmentModalElement" class="modal fade card-detail-layout" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-fulfillment mt-2">
      <div class="modal-content modal-content-fulfillment mt-2">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">
            {{ orderName }}
          </h5>
          <nav
            aria-label="paginacion"
            class="position-absolute top-2 end-4 d-flex justify-content-end align-items-center pb-2"
          >
            <ul class="m-0 ps-1 pe-1 text-xs">
              <span class="page">{{ currentIndex + 1 }} / {{ tableStore.checkedList.length }}</span>
            </ul>
            <ul class="pagination justify-content-end m-0">
              <li :class="['page-item', tableStore.checkedList.length === 1 ? 'disabled' : '']">
                <a class="page-link" @click="previousOrder" :style="{ pointerEvents: tableStore.checkedList.length === 1 ? 'none' : 'auto' }">&lt;</a>
              </li>
              <li :class="['page-item', tableStore.checkedList.length === 1 ? 'disabled' : '']">
                <a class="page-link" @click="nextOrder" :style="{ pointerEvents: tableStore.checkedList.length === 1 ? 'none' : 'auto' }">&gt;</a>
              </li>
            </ul>
          </nav>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="resetModal()"
          ></button>
        </div>
        <div class="modal-body position-relative">
          <ribbon v-if="currentOrder.status == 'PREPARADO' || currentOrder.status === 'ANULADO' || currentOrder.status == 'ERROR'" :label="currentOrder.status"></ribbon>
          <div v-if="disableButton || loading" class="overlay position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
            <img class="img-loading" src="@/assets/img/loading-customer.svg" alt="Loading..."/>
          </div>
          <fulfillment-container
            ref="fulfillmentForm"
            v-model="fulfillment"
            :order="currentOrder"
            :shop="shop" />
        </div>
        <div class="modal-footer pt-0 pb-0">
          <argon-loading-button
            v-if="currentOrder.status!='PREPARADO' && currentOrder.status!='ANULADO' && currentOrder.status!='ERROR'"
            class="float-end mt-2"
            color="primary"
            variant="gradient"
            size="sm"
            :label="disableButton ? 'Expidiendo...' : 'Expedir'"
            :loading="disableButton"
            :disable-button="disableButton"
            @click="warningBeforeSave"
          />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
        :close-handler="closeSnackbar" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
// .modal-content {
//   max-width: 100% !important;
//   min-width: 64rem !important;
//   width: auto !important;
//   display: inline-block;
// }

// .modal-dialog {
//   min-width: 64rem !important;
// }
.overlay {
  background-color: rgba(0, 0, 0, 0);
  z-index: 1010;
}

.img-loading {
  z-index: 1011;
  width: 40px;
  height: 40px;
}
</style>