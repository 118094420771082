<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const store = useStore();
const noUrlRoutes = ["/imports", "/marketplace", "/account", "/pickup", "/pricing", "/delivery"];

onMounted(() => {
});

const breadcrumbs = computed(() => {
  const currentPath = route.path;
  const pathSegments = currentPath.split("/");
  pathSegments.pop();

  const breadcrumbsArray = [];
  while (pathSegments.length > 1) {
    const parentPath = pathSegments.join("/");
    const parentRoute = router.resolve({ path: parentPath });

    breadcrumbsArray.unshift({
      path: noUrlRoutes.includes(parentPath) ? null : parentRoute.path,
      text: parentRoute.meta.breadcrumb || parentRoute.name,
    });

    pathSegments.pop();
  }
  breadcrumbsArray.push({
    path: noUrlRoutes.includes(route.path) ? null : route.path,
    text: route.meta.breadcrumb || route.name,
  });

  return breadcrumbsArray;
});

const isRTL = computed(() => store.state.isRTL);
const isNavFixed = computed(() => store.state.isNavFixed);
</script>

<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="!isRTL && 'me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" :class="isRTL && 'ps-2'">
        <a
          :class="{
            'text-dark': isNavFixed,
            'text-white': !isNavFixed,
          }"
        >
          <i class="ni ni-box-2"></i>
        </a>
      </li>
      <li
        v-for="(crumb, index) in breadcrumbs"
        :key="index"
        class="text-sm breadcrumb-item"
        :class="{
          'text-dark': isNavFixed,
          'text-white': !isNavFixed,
          active: index === breadcrumbs.length - 1,
        }"
      >
        <router-link
          v-if="crumb.path && index !== breadcrumbs.length - 1"
          :to="crumb.path"
          class="link-active"
          :class="{
            'text-dark': isNavFixed,
            'text-white': !isNavFixed,
          }"
        >
          {{ crumb.text }}
        </router-link>
        <span
          v-else
          :class="{
            'text-dark': isNavFixed,
            'text-white': !isNavFixed,
          }"
        >
          {{ crumb.text }}
        </span>
      </li>
    </ol>
    <h5
      class="mb-0 font-weight-bolder"
      :class="{
        'text-dark': isNavFixed,
        'text-white': !isNavFixed,
      }"
    >
      {{ breadcrumbs[breadcrumbs.length - 1]?.text }}
    </h5>
  </nav>
</template>

<style scoped>
</style>
