<script setup>
import { useRoute } from "vue-router";
import { onMounted, watch, ref, onBeforeMount, onBeforeUnmount } from "vue";
import { dateUtils } from "@/utils";
import { trackingStore } from "@/_store/trackingStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import TimelineList from "@/components/tracking/ArgonTracking.vue";
import TimelineItem from "@/components/tracking/ArgonTrackingItem.vue";
import { getStatusLabel } from "@/maestros/shipmentStatus";
import { useStore } from "vuex";
import CopyValue from "@/components/CopyValue.vue"

const route = useRoute();
const trackingStores = trackingStore();
const snackbar = ref(null);
const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const body = document.getElementsByTagName("body")[0];

let track = ref(null);
let copiedSeguimiento = ref(false);

const copySeguimiento = async () => {
  copiedSeguimiento.value = true;
  await navigator.clipboard.writeText(track.value.tracking.data[0].tracking);
  setTimeout(() => {
    copiedSeguimiento.value = false;
  },250);
};

onMounted(() => {
  let request={
    type: "locator",
    number: ""
  }
  if(route.query.locator){
    request.number = route.query.locator;
    request.type = "locator";
  }else if(route.query.id){
    request.number = route.query.id;
    request.type = "id";
  }else{
    return;
  }
  trackingStores.getTracking(request);
});

function getIcon(status) {
  const statusConfig = {
    RECOGIDA_PENDIENTE: { color: "info", component: "ni ni-pin-3" },
    RECOGIDO: { color: "info", component: "fa fa-truck" },
    EN_TRANSITO: { color: "warning", component: "fa fa-truck" },
    EN_REPARTO: { color: "warning", component: "fa fa-truck" },
    ENTREGADO: { color: "green", component: "ni ni-pin-3" },
    FINALIZADO: { color: "green", component: "ni ni-pin-3" },
    ENTREGA_CANCELADA: { color: "green", component: "ni ni-pin-3" },
    INCIDENCIA: { color: "danger", component: "fa fa-exclamation-triangle" },
    ANULADO: { color: "danger", component: "fa fa-ban" },
  };
  const defaultConfig = { color: "warning", component: "fa fa-truck" };
  return statusConfig[status] || defaultConfig;
}

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

function capitalizeFirstLetter(text) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

watch(
  () => copySeguimiento,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Copiado",
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => trackingStores.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => trackingStores.tracking,
  (value) => {
    if (value) {
      track.value = value;
    }
  }
);
</script>
<template>
  <main
    class="main-content card-detail-layout"
    :style="{
      backgroundImage: 'url(' + '/img/logos/public-back-ground.png' + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
    }"
  >
    <div class="row">
      <div class="align-item-center">
        <img
          class="me-3 mb-2 mt-3"
          src="/img/logos/BeePaketLogo.png"
          alt="logo"
          width="200px"
        />
      </div>
    </div>
    <section class="">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8 col-xl-6 col-sm-12">
          <div class="card h-100" v-if="track">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <span
                    >Su paquete se encuentra en estado <span
                      class="col-form-label"
                      :class="`text-${getIcon(track.shipment.status).color}`"
                      >{{ getStatusLabel(track.shipment.status) }}
                    </span></span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span>Nº de seguimiento <span class="fw-bolder">{{ track.shipment.locator }}</span></span>
                  <copy-value :valueToCopy="track.tracking.data[0].tracking" :copied-value="copiedSeguimiento" />
                  <!-- <a
                    v-if="!copiedSeguimiento"
                    class="ms-2"
                    @click="copySeguimiento"
                    >
                  <i class="fa-regular fa-copy hover-copy" v-tooltip data-bs-title="Copiar"></i></a>
                  <a v-else class="ms-2"
                    ><i class="fa-solid fa-check me-sm-2"></i
                  ></a> -->
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="container px-4 border border-radius-lg p-2">
                    <div class="row gx-5">
                      <div class="col-3">
                        <i class="text-info text-gradient ni ni-square-pin ni-4x" aria-hidden="true"></i>
                      </div>
                      <div class="col-9">
                        <h5 class="mb-0">Origen</h5>
                        <span class="size-span"
                          >{{ track.shipment.pickup.postal_code }},
                          {{ track.shipment.pickup.city }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="container px-4 border border-radius-lg p-2">
                    <div class="row gx-5">
                      <div class="col-3">
                        <i class="ni ni-pin-3 ni-4x text-green" aria-hidden="true"></i>
                      </div>
                      <div class="col-9">
                        <h5 class="mb-0">Destino</h5>
                        <span class="size-span"
                          >{{ track.shipment.delivery.postal_code }},
                          {{ track.shipment.delivery.city }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <span class="size-span"
                  >Será entregado por <span class="fw-bolder">{{
                    capitalizeFirstLetter(track.shipment.courier)
                  }}</span></span
                >
              </div>
              <div class="row">
                <div v-if="track" id="list-tracking">
                  <hr class="mt-2 mb-2"/>
                  <timeline-list v-if="track">
                    <timeline-item
                      v-for="(item, key) in track.tracking.data"
                      :key="key"
                      :icon="getIcon(item.status)"
                      :title="
                        getStatusLabel(item.status)
                      "
                      :name="item.description"
                      :date-time="dateUtils.formatDate(item.date)"
                    />
                  </timeline-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="position-fixed top-1 end-1 z-index-2">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </main>
</template>
<style>
.initial-m {
  margin-left: 1px !important;
}

.final-m {
  margin-right: 1px !important;
}

.size-span {
  font-size: 0.9rem;
}

#list-tracking {
  max-height: 80vh;
  overflow-y: auto;
}

.hover-copy:hover {
  cursor: pointer !important;
}
</style>