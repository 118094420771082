<script setup>
import { ref, onMounted, watch } from 'vue';
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";


const emit = defineEmits(["addProduct"]);
const eanInput = ref(null);
const code = ref("")

const props = defineProps({
  products: {
    type: Array,
    default() {
      return [];
    },
  },
  order: {
    type: [String, Number],
    default() {
      return "";
    },
  },
});

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

onMounted(() => {
  document.querySelector("#fulfillmentModal").addEventListener("show.bs.modal", () => {
    if (eanInput.value) {
      setTimeout(() => {
        eanInput.value?.blur()
        eanInput.value?.focus();
      }, 900)
    }
  });
});

function searchEan() {
  const product = props.products.find((product) => product.variant_ean == code.value)
  code.value = ""
  if (!product) {
    snackbar.value = {
        title: "Error",
        message: `<ul>Producto no encontrado</ul>`,
        type: "danger",
        color: "white",
      };
    return
  }
  emit("addProduct", { ...product });
}

watch(
  () => props.order,
  // eslint-disable-next-line no-unused-vars
  (value) => {
    if (value) {
      if (eanInput.value) {
        setTimeout(() => {
          eanInput.value?.blur()
          eanInput.value?.focus();
        }, 900)
      }
    }
  }
);

</script>
<template>
  <div class="card card-detail-layout">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <input
            ref="eanInput"
            v-model="code"
            class="w-100 form-control"
            placeholder="Escaner ean"
            name="code1"
            @keyup.enter="searchEan"
          />
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <argon-snackbar
          v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
          :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
          :close-handler="closeSnackbar" />
        </div>
    </div>
  </div>
</template>
