<script setup>
import ArgonSelect from "@/components/ArgonSelect.vue";
import Shopify from "@/assets/img/small-logos/logo-shopify.svg";
import Prestashop from "@/assets/img/small-logos/logo-prestashop.webp";
import Woocommerce from "@/assets/img/small-logos/logo-woocommerce.png";
import { watch, ref } from "vue";

const emits = defineEmits(["selectedChange"]);
const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Array, String, Number],
    default: "",
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  placeholder: {
    type: String,
    default: "",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  nameValue: {
    type: String,
    default: "value",
  },
  nameLabel: {
    type: String,
    default: "label",
  },
  description: {
    type: String,
    default: "description",
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  separator: {
    type: String,
    default: "-",
  },
  menuWidth: {
    type: String,
    default: "300",
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
});
const localValue = ref(props.modelValue);
function changedSelectService(item) {
  emits("selectedChange", item);
}
watch(
  () => localValue.value,
  (value) => {
    emits("update:modelValue", value);
  }
);
const lisOptions = ref([]);
watch(
  () => props.options,
  (val) => {
    if (val && val.length > 0) {
      if (props.showIcon) {
        lisOptions.value = val.map((shop) => {
          let image = "";
          switch (shop.value) {
            case "SHOPIFY":
              image = Shopify;
              break;
            case "PRESTASHOP":
              image = Prestashop;
              break;
              default:
              image = Woocommerce;
              break;
          }
          return {
            ...shop,
            image,
          };
        });
      } else {
        lisOptions.value = val;
      }
    }
  },
  { immediate: true }
);
</script>
<template>
  <argon-select
    :id="id"
    v-model="localValue"
    :name="name"
    :is-required="isRequired"
    :options="lisOptions"
    :error="error"
    :name-value="nameValue"
    :name-label="nameLabel"
    :description="description"
    :filterable="filterable"
    :disabled="disabled"
    :show-icon="showIcon"
    @selected-change="changedSelectService"
  >
  </argon-select>
</template>

