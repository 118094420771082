import { defineStore } from "pinia";
import { syncService, marketplaceService } from "@/_services";

export const syncListStore = defineStore("synchronizationList", {
  state: () => ({
    syncs: null,
    error: null,
    sync: null,
    syncById: null
  }),
  actions: {
    getAllSynchronizations(pagination, filters, sort) {
      syncService
        .getAllSynchronizations(pagination, filters, sort)
        .then((results) => (this.syncs = results))
        .catch((reason) => (this.error = reason));
    },
    syncShop(shopType) {
      this.sync = false
      this.error = null
      marketplaceService
        .syncShop(shopType)
        .then(() => {
          this.sync = true
        })
        .catch((reason) => {
          this.error = reason;
          this.sync = false
        });
    },
    syncShopById(shopId) {
      this.syncById = false
      this.error = null
      marketplaceService
        .syncShopyById(shopId)
        .then(() => {
          this.syncById = true
        })
        .catch((reason) => {
          this.error = reason;
          this.syncById = false
        });
    },
  },
});
