<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import { orderListStore } from "@/_store/ordersStore";
import { fulfillmentStore as fulfillmentOrderStore } from "@/_store";
import { useRoute } from "vue-router";
import FulfillmentModal from './FulfillmentModal.vue';
import OrderStatus from "@/components/badges/OrderStatus.vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue"; 
import NotFound from "@/assets/img/image.png";
import { useStore } from "vuex";
import { dateUtils } from "@/utils";
import { argonTableStore } from "@/_store/argonTableStore";
import TabsBootstrap from "@/components/TabsBootstrap";
import FulfillmentChip from "@/components/FulfillmentChip.vue";

const store = useStore();
const bootstrap = store.state.bootstrap;
const orderStore = orderListStore();
const fulfillmentStore = fulfillmentOrderStore();
const tableStore = argonTableStore();
const route = useRoute();
const localValue = ref(null);
const table_product = ref([]);
let fecha = ref("");
const currentTab = ref("product");
const modalFulfillment =ref(null)
const tabs = ref([
  { label: "Productos", value: "product" },
  { label: "Fulfillments", value: "fulfillment" },
]);
const actions = ref([
  [
    { label: "Preparar", event: "onPreparar" },
  ],
]);
const events = {
  onPreparar : showFulfillmentModal
};
function showFulfillmentModal() {
  tableStore.clear();
  tableStore.addSelectedRow(route.params.id);
  modalFulfillment.value = new bootstrap.Modal(document.getElementById("fulfillmentModal"), {
    keyboard: true,
  });
  modalFulfillment.value.show();
}
function hideFulfillmentModal() {
  modalFulfillment.value.hide();
}
onMounted(() => {
  orderStore.getOrderById(route.params.id);
});
const columns = [
  {
    header: "",
    field: "image",
    sort: false,
    width: "w-1",
  },
  {
    header: "Nombre",
    field: "product_name",
    sort: false,
  },
  {
    header: "Ean",
    field: "variant_ean",
    sort: false,
  },
  {
    header: "Sku",
    field: "variant_sku",
    sort: false,
  },
  {
    header: "Cantidad",
    field: "quantity",
    sort: false,
    position: "end",
  },
  {
    header: "Kg",
    field: "variant_weight",
    sort: false,
    position: "end",
  },  
  {
    header: "Precio €",
    field: "price",
    sort: false,
    position: "end",
  },   
  {
    header: "Estado",
    field: "status",
    sort: false,
    width: "w-5",
    position: "center",
  },
];
function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

watch(
  () => orderStore.orderId,
  (value) => {
    localValue.value = value;
    table_product.value.data = value.products;
    fecha.value = dateUtils.formatDate(
      localValue.value.date,
      "YYYY-MM-DDTHH:mm"
    );
    fulfillmentStore.getByOrder(localValue.value._id);
  }
);

function getPosition(field) {
  const column = columns.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <argon-detail v-if="localValue" :group-actions="actions" @click="triggerEvent">
              <template #title>
                <argon-input
                  v-if="localValue.number"
                  id="name"
                  v-model="localValue.number"
                  :max-length="40"
                  type="text"
                  placeholder="Nombre"
                  size="lg"
                  font-style="h4 fw-bold"
                  :disabled="true"
                />
              </template>
              <template #state>
                <order-status :item="localValue.status" :tooltip-message="localValue.error"></order-status>
              </template>
              <template #body>
                <div class="row spacing">
                  <div class="col-3">
                    <div class="card h-100">  
                      <div class="card-header">Pedido</div>                    
                      <div class="card-body">                       
                        <div class="row">
                          <div class="col-4">
                            <label for="inputPassword6" class="col-form-label"
                              >Fecha</label
                            >
                          </div>
                          <div class="col-8">
                            <argon-input
                              id="date"
                              v-model="fecha"
                              type="datetime-local"
                              :disabled="true"
                            ></argon-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label for="inputPassword6" class="col-form-label"
                              >Importe €</label
                            >
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <argon-input
                                id="total_price"
                                v-model="localValue.total_price"
                                :disabled="true"
                              ></argon-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label for="total_weight" class="col-form-label"
                              >Total kg</label
                            >
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <argon-input
                                id="total_weight"
                                v-model="localValue.total_weight"
                                :disabled="true"
                              ></argon-input>
                            </div>
                          </div>
                        </div>                         
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card h-100">
                      <div class="card-header">Cliente</div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-3">
                            <label class="col-form-label">Nombre</label>
                          </div>
                          <div class="col-9">
                            <argon-input
                              id="name"
                              v-model="localValue.customer.contact.name"
                              :disabled="true"
                            ></argon-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label for="inputPassword6" class="col-form-label"
                              >Mail</label
                            >
                          </div>
                          <div class="col-9">
                            <argon-input
                              id="email"
                              v-model="localValue.customer.contact.email"
                              :disabled="true"
                            ></argon-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label for="inputPassword6" class="col-form-label"
                              >Teléfono</label
                            >
                          </div>
                          <div class="col-9">
                            <argon-input
                              id="phone"
                              v-model="localValue.customer.contact.phone"
                              :disabled="true"
                            ></argon-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="card h-100">
                      <div class="card-header">Entrega</div>
                      <div class="card-body">
                        <div class="row">
                            <div class="col-3">
                              <label for="inputPassword6" class="col-form-label"
                                >Dirección</label
                              >
                            </div>
                            <div class="col-9">
                              <argon-input
                                id="address"
                                v-model="localValue.customer.address.text"
                                :disabled="true"
                              ></argon-input>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-3">
                              <label for="inputPassword6" class="col-form-label"
                                >Notas</label
                              >
                            </div>
                            <div class="col-9">
                              <argon-input
                                id="observations"
                                v-model="localValue.observations"
                                :disabled="true"
                              ></argon-input>
                            </div>
                          </div>
                        </div>                       
                    </div>
                  </div>
                </div>
                <div class="row spacing">
                  <div class="card mt-3">
                    <div class="card-body">
                      <tabs-bootstrap
                        v-model="currentTab"
                        :tabs="tabs"
                      ></tabs-bootstrap>
                      <div v-if="currentTab === 'product'">
                        <simple-table :headers="columns" :items="table_product">
                          <template #default="{ item }">
                            <argon-td :position="getPosition('image')">
                              <div class="d-flex align-items-center">
                                <argon-avatar
                                  class="p-0 me-2"
                                  :image="item.image || NotFound"
                                  size="sm"
                                  shadow="sm"
                                  alt="Avatar"
                                />
                              </div>
                            </argon-td>
                            <argon-td
                              :text-secondary="item.product_name"
                              :position="getPosition('product_name')"
                            ></argon-td>
                            <argon-td
                              :text-secondary="item.variant_ean"
                              :position="getPosition('variant_sku')"
                            />
                            <argon-td
                              :text-secondary="item.variant_sku"
                              :position="getPosition('variant_sku')"
                            />
                            <argon-td
                              :text-secondary="item.quantity"
                              :position="getPosition('quantity')"
                            />
                            <argon-td
                              :text-secondary="item.variant_weight.toFixed(2)"
                              :position="getPosition('variant_weight')"
                            />                           
                            <argon-td
                              :text-secondary="item.price.toFixed(2)"
                              :position="getPosition('price')"
                            /> 
                            <argon-td :position="getPosition('status')">
                              <order-status :item="item.status"></order-status>
                            </argon-td>
                          </template>
                        </simple-table>
                      </div>
                    </div>
                    <fulfillment-chip :fulfillments="fulfillmentStore.fulfillmentByOrder" v-if="currentTab === 'fulfillment' && fulfillmentStore.fulfillmentByOrder"/>
                  </div>
                </div>
              </template>
            </argon-detail>
          </div>
        </div>
      </div>
    </div>
    <fulfillment-modal id="fulfillmentModal" @hide="hideFulfillmentModal"></fulfillment-modal>
  </div>
</template>