<script setup>
import TableApiKeys from "@/views/security/components/TableApiKeys.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import { ref, onMounted, watch, inject } from "vue";
import { apiKeyStore } from "@/_store/apikeyStore.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import moment from "moment";
import CopyValue from "@/components/CopyValue.vue";

const sweetAlert = inject("$swal");
const visibleKeys = ref([]);
const snackbar = ref(null);
const apiStore = apiKeyStore();
let copiedKey = ref(false);
const headers = [
  {
    header: "Nombre",
    field: "name",
    sort: false,
    width: "w-28",
  },
  {
    header: "Api Key",
    field: "apiToken",
    sort: false,
    width: "w-60",
  },
  {
    header: "Creado",
    field: "createdAt",
    sort: false,
  },
  {
    header: "Acciones",
    sort: false,
  },
];
const listTable = ref({ data: [] });
function inputValues() {
  apiStore.getApiKeys();
}

onMounted(() => {
  getApiKey();
});

function showHideKey(id) {
  if (visibleKeys.value.includes(id)) {
    visibleKeys.value = visibleKeys.value.filter((keyId) => keyId !== id);
  } else {
    visibleKeys.value.push(id);
  }
}

function deleteApiKey(item) {
  sweetAlert({
    title: "¿Está seguro que desea eliminar el api key?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      apiStore.deleteApiKeys(item);
    }
  });
}

function getApiKey() {
  apiStore.getApiKeys();
}

function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

function errorValidations(message) {
  snackbar.value = {
    title: "Error",
    message: message,
    type: "danger",
    color: "white",
  };
}

watch(
  () => apiStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => apiStore.delete,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Eliminado",
        message: "Se ha eliminado el api  key",
        type: "info",
        color: "white",
      };
      apiStore.delete = null;
      getApiKey();
    }
  }
);

watch(
  () => apiStore.list,
  (value) => {
    if (value) {
      listTable.value.data = value;
    }
  }
);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="row">
    <div class="col">
      <div class="card h-100">
        <div class="card-header">API Keys</div>
        <div class="card-body">
          <div class="row">
            <div class="col style-api">
              <table-api-keys
                @errorValidations="errorValidations"
                @inputValues="inputValues"
                :headers="headers"
                :items="listTable"
                :show-inputs="true"
              >
                <template #default="{ item }">
                  <argon-td
                    :text-primary="item.appName"
                    :position="getPosition('appName')"
                  />
                  <argon-td
                    :text-secondary="item.apiToken"
                    :position="getPosition('apiToken')"
                    :has-hide="!visibleKeys.includes(item._id)"
                    max-width="599.6px"
                  />
                  <argon-td
                    :position="getPosition('createdAt')"
                    :text-secondary="
                      moment(item.createdAt).format('DD/MM/YYYY')
                    "
                  ></argon-td>
                  <argon-td>
                    <div class="d-flex align-items-center">
                      <i
                        v-if="!visibleKeys.includes(item._id)"
                        class="fa-solid fa-eye text-sm opacity-10 ms-2 hover"
                        v-tooltip
                        data-bs-title="Ver"
                        @click="showHideKey(item._id)"
                      ></i>
                      <i
                        v-else
                        class="fa-solid fa-eye-slash text-sm opacity-10 ms-2 hover"
                        v-tooltip
                        data-bs-title="Ocultar"
                        @click="showHideKey(item._id)"
                      ></i>
                      <copy-value
                        :valueToCopy="item.apiToken"
                        :copied-value="copiedKey"
                      />
                      <i
                        class="fa fa-trash text-sm opacity-10 ms-2 hover"
                        v-tooltip
                        data-bs-title="Eliminar"
                        @click="deleteApiKey(item._id)"
                      ></i>
                    </div>
                  </argon-td>
                </template>
              </table-api-keys>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style scoped>
.hover:hover {
  cursor: pointer !important;
}
</style>