<script setup>
import { watch,ref, onMounted,inject } from "vue";
import { marketplaceStore } from "@/_store";
import { syncListStore } from "@/_store/syncStore";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import NewShopModal from "./NewShopModal.vue";
import MarketplaceAvatar from "@/components/MarketplaceAvatar.vue";
const sweetAlert = inject("$swal");
const syncStore = syncListStore();

const store = marketplaceStore();
const snackbar = ref(null);
const shopId= ref(null);
let showShopModal = ref(false);

const closeSnackbar = () => {
  snackbar.value = null;
};
function getPosition(field) {
  const column = headers.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
const listTable=ref([])
const headers = [  
  {
    header: "Marketplace",
    field: "marketplace",
    position: "center",  
    sort: false,
    width: "w-20"
  },
  {
    header: "Nombre",
    field: "name",
    sort: false,
  },
  {
    header: "Url",
    field: "url",
    sort: false
  },  
  {
    header: "Acciones",
    field: "acciones",
    position: "center",
    sort: false,
    width: "w-10"
  }
];
function closeNewShopModal(){
  shopId.value= null;
  showShopModal.value = false;
}

function newShop(){
  shopId.value= null;
  showShopModal.value = true;
}
function editShop(id){
  shopId.value= id
  showShopModal.value = true;
}
function syncShop(id) {
  syncStore.syncShopById(id);
}
function successModal(msg){
  snackbar.value = {
        title: "Actualizado",
        message: `<ul>${msg}</ul>`,
        type: "info",
        color: "white",
      };
  reload();
}
function deleteShop(id){
  sweetAlert({
    title: "¿Está seguro?",
    text: "Se eliminará la suscripción de la tienda",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
    cancelButtonText: "Cancelar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      store.deactivateShop(id);
    }
  });
}
onMounted(() => {
  reload();
});
function reload(){
  store.getAllShops();
}
watch(
  () => store.shops,
  (newVal) => {
    if (newVal) {
      listTable.value = newVal;
    }
  }
);
watch(
  () => store.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message? value.message : "Lo sentimos, ha ocurrido un problema,intente más tarde",
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => syncStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message? value.message : "Lo sentimos, ha ocurrido un problema,intente más tarde",
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => syncStore.syncById,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Sincronización iniciada</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);
watch(
  () => store.deactivateShopState,
  (value) => {
    if (value) {
    snackbar.value = {
      title: "Actualizado",
      message: `<ul>Suscripción eliminada</ul>`,
      type: "info",
      color: "white",
    };
    reload();
    }
  }
);
</script>

<template>
  <div id="accounts" class="card">
    <div class="card-header">
      Marketplaces
    </div>
    <div class="card-body">
      <argon-button
        class="me-2 btn-sm mb-2"
        color="primary"
        @click="newShop"
        >
        <i class="fa fa-plus me-sm-2"></i>
        Nuevo</argon-button
      >
      <simple-table
      v-if="listTable"
      :headers="headers"
      :items="listTable"
      @on-reload="reload"
      @page-changed="reload"
      >
        <template #default="{ item }">
          <argon-td :position="getPosition('marketplace')">
            <marketplace-avatar
              :item="item.marketplace"
            ></marketplace-avatar>
          </argon-td>
          <argon-td
            :text-primary="item.name"
            :position="getPosition('name')"
          />
          <argon-td
            :text-primary="item.url"
            :position="getPosition('url')"
          />           
          <argon-td
            :position="getPosition('acciones')" >
            <div class="">
              <a
                id="idsync"
                href="javascript:;"
                @click="syncShop(item._id)"
              >
                <i v-tooltip data-bs-title="Sincronizar Productos" class="fa fa-refresh text-sm opacity-10 ms-2 hover"/>
              </a>
              <a
                id="idsync"
                href="javascript:;"
                @click="editShop(item._id)"
              >
                <i v-tooltip data-bs-title="Editar" class="fa fa-pencil text-sm opacity-10 ms-2 hover"/>
              </a>
              <a
                id="idsync"
                href="javascript:;"
                @click="deleteShop(item._id)"
              >
                <i v-tooltip data-bs-title="Eliminar" class="fa fa-trash text-sm opacity-10 ms-2 hover"/>
            </a>
            </div>
          </argon-td>
        </template>
      </simple-table>
    </div>
  </div>
  <new-shop-modal v-if="showShopModal"  :id="shopId" @close="closeNewShopModal" @success="successModal"  />
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar 
    v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
