<script setup>
const emit = defineEmits(["removeOption"]);

const props = defineProps({
  index: {
    type: Number,
    default: -1,
  },
  text: {
    type: [String, Array],
    default: "",
  },
});

function removeOption() {
  emit("removeOption", props.index);
}
</script>

<template>
  <div class="col-auto max-width d-flex multi-lighten p-0">
    <span class="multi-search text-search badge-secondary text-sm">
      <span class="text-multi">{{ text }}</span>
      <button type="button" class="choices__button" aria-label="Remove item: 'PAQ10'" data-button=""  @click="removeOption()">Remove item</button>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.max-width,.badge-secondary{
  max-width: 100%;
}
.text-multi {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-detail-layout .multi-search{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.fa-times{
  color: #393a3b;
  font-weight: bold;
}
.multi-search {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 0 8px;
}

.text-multi {
  flex: 1;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.choices__button {
  flex-shrink: 0;
  margin-left: 8px;
}

</style>