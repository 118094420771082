import createAxios from "./axiosHttp";

function getAllContacts(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `contact/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getContactById(idContact) {
  const axios = createAxios();
  let urlPath = `contact/${idContact}`;
  return axios.get(urlPath);
}
function getContactByDefault(customerId) {
  const axios = createAxios();
  let idCustomer=customerId;
  if(!idCustomer){
    const user = JSON.parse(localStorage.getItem('user'));
    idCustomer=user.extraData.customerId
  }
  let urlPath = `contact/pickup/customer/${idCustomer}/default`;
  return axios.get(urlPath);
}
function getAllContactsByCustomer(customerId, query,pagination) {
  const axios = createAxios();
  let urlPath = `contact/customer/${customerId}/ql`;
  const ql = {
    query,
    pagination,
    sort: { "contact.name": -1 },
  };
  return axios.post(urlPath, ql);
}
function updateContact(id, body) {
  const axios = createAxios();
  let urlPath = `contact/${id}`;
  return axios.put(urlPath, body);
}

function insertContact(body) {
  const axios = createAxios();
  let urlPath = `contact`;
  return axios.post(urlPath, body);
}

function deleteContact(idContact) {
  const axios = createAxios();
  let urlPath = `contact/${idContact}`;
  return axios.delete(urlPath);
}

function deleteListContact(idContacts) {
  const axios = createAxios();
  let peticiones = [];
  for(let idContact of idContacts){
    const urlPath = `contact/${idContact}`;
    peticiones.push(axios.delete(urlPath));
  }
  return Promise.all(peticiones);
}

function getContactsAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/contact/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

export const contactService = {
  getAllContacts,
  getContactById,
  deleteContact,
  updateContact,
  insertContact,
  getContactsAsExcel,
  getContactByDefault,
  getAllContactsByCustomer,
  deleteListContact
};
