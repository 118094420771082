<script setup>
import ArgonSelect from "@/components/ArgonSelect.vue";
import { userStore } from "@/_store/userStore";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import ups from "@/assets/img/logos/partners/ups.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import propio from "@/assets/img/logos/logo.svg";
import { watch, ref, computed } from "vue";

const emits = defineEmits(["selectedChange"]);
const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Array, String, Number],
    default: "",
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  placeholder: {
    type: String,
    default: "",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  nameValue: {
    type: String,
    default: "value",
  },
  nameLabel: {
    type: String,
    default: "label",
  },
  description: {
    type: String,
    default: "description",
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  separator: {
    type: String,
    default: "-",
  },
  menuWidth: {
    type: String,
    default: "300",
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
});
const localValue = ref(props.modelValue);
function changedSelectService(item) {
  emits("selectedChange", item);
}
const userStores = userStore();
const beeImage = computed(() => (isAgency.value ? propio_agency : propio));
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
watch(
  () => localValue.value,
  (value) => {
    emits("update:modelValue", value);
  }
);
const lisOptions = ref([]);
watch(
  () => props.options,
  (val) => {
    if (val && val.length > 0) {
      if (props.showIcon) {
        lisOptions.value = val.map((servicio) => {
          let image = "";
          switch (servicio.courier) {
            case "CEX":
              image = cex;
              break;
            case "CTT":
              image = ctt;
              break;
            case "UPS":
              image = ups;
              break;
            default:
              image = beeImage;
              break;
          }
          return {
            ...servicio,
            image,
          };
        });
      } else {
        lisOptions.value = val;
      }
    }
  },
  { immediate: true }
);
</script>
<template>
  <argon-select
    :id="id"
    v-model="localValue"
    :name="name"
    :is-required="isRequired"
    :options="lisOptions"
    :error="error"
    :name-value="nameValue"
    :name-label="nameLabel"
    :description="description"
    :filterable="filterable"
    @selectedChange="changedSelectService"
    :disabled="disabled"
    :show-icon="showIcon"
  >
  </argon-select>
</template>

