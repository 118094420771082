<script setup>
import { onMounted, watch, ref, inject, computed, provide } from "vue";
import { ArgonLoadingButton } from "@/components";
import Prestaciones from "./Prestaciones.vue";
import Tracking from "@/components/Tracking.vue";
import Bultos from "@/components/forms/Bultos.vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import ArgonInput from "@/components/ArgonInput.vue";
/* import ArgonSelect from "@/components/ArgonSelect.vue"; */
import ArgonSelectCourier from "@/components/ArgonSelectCourier.vue";
import ValuationsSubTable from "@/components/ValuationsSubTable.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout";
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import LogisticsForm from "@/components/forms/LogisticsForm.vue";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { userStore } from "@/_store/userStore";
import { useRoute, useRouter } from "vue-router";
import { dateUtils, objectUtils } from "@/utils";
import { shipmentDetailStore, shipmentListStore } from "@/_store";
import { valuationStore as valuationStoreRequest } from "@/_store/valuationStore";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import FulfillmentShipment from "@/components/FulFillmentShipment.vue";
import ModalChangeStatus from "./ModalChangeStatus.vue";
import ModalCalculateValuation from "./ModalCalculateValuation.vue";
import PackagesSorter from "./PackagesSorter.vue";
import DocumentsButton from "@/components/gallery-files/DocumentsButton.vue";
import { fileViewStore } from "@/components/gallery-files/fileViewStore";

const route = useRoute();
const router = useRouter();
const userStores = userStore();
const valuationStore = valuationStoreRequest();
const shipmentStore = shipmentDetailStore();
const shipmentsStore = shipmentListStore();
const sweetAlert = inject("$swal");
const fileStore = fileViewStore();

let actionShipments = ref(false);
provide("router", router);
provide("route", route);
let tabSelected = ref("bultos");
const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
let customerIdResolver = ref(userStores.userSigned?.extraData.customerId);
const tabs = ref([
  { label: "Bultos", value: "bultos" },
  { label: "Prestaciones", value: "prestaciones" },
  { label: "Mercancias", value: "mercancias" },
]);
let disableButton = ref(false);
const disableButtonValuation = ref(false);
const statusChange = ref([
  { label: "Recogido", value: "RECOGIDO" },
  { label: "En Transito", value: "EN_TRANSITO" },
  { label: "En Reparto", value: "EN_REPARTO" },
  { label: "Entregado", value: "ENTREGADO" },
  { label: "Entrega Cancelada", value: "ENTREGA_CANCELADA" },
  { label: "Incidencia", value: "INCIDENCIA" },
]);
const baseShipment = {
  _id: "",
  origin: "BEEFRONT",
  reference: "",
  locator: "",
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  delivery: {
    date: "",
    contact: {
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    },
    address: {
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    },
    schedules: {
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    },
    observations: "",
    internacional: false,
    in_point: false,
    point_code: "",
    point_name: "",
    own: false,
  },
  pickup: {
    date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
    contact: {
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    },
    address: {
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    },
    schedules: {
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    },
    observations: "",
    internacional: false,
    in_point: false,
    point_code: "",
    point_name: "",
    number: "",
    with_pickup: true,
    own: false,
  },
  customer_id: "",
  customer: {
    code: "",
    contact: {
      name: "",
    },
  },
  department: "",
  packages: [],
  packages_sorter: [],
  service: "",
  internacional: false,
  merchandise_description: "",
  status: "",
  courier: "",
  features: {
    refund_amount: 0,
    insurance: 0,
    delivery_on_saturday: false,
    returns: false,
  },
  errors: [],
  status_description: "",
  metadata: null,
  uri_tracking: "",
  warning: [],
};
const fileInput = ref(null);

const rules = {
  date: { required: helpers.withMessage("Fecha es requerida", required) },
  service: { required: helpers.withMessage("Servicio es requerido", required) },
  packages: {
    required: helpers.withMessage("Bultos es requerido", required),
  },
};
let localValue = ref(null);
const v$ = ref(useVuelidate(rules, localValue));
const disablesInputs = ref(false);
let minDate = ref("");
let maxDate = ref("");
const newDescription = ref(null);
const keyLabel = ref(0);
const actions = ref([
  [{ label: "Etiqueta", event: "onLabelPrint" }],
  [
    { label: "Copiar", event: "onCopyShipment" },
    { label: "Devolver", event: "onReturnShipment" },
  ],
  [{ label: "Anular", event: "onCancel" }],
]);
const events = {
  onLabelPrint: getShipmentsLabel,
  onJustificantePrint: justificante,
  onUploadAduana: uploadAduana,
  onCancel: cancelShipment,
  onCopyShipment: actionCopyShipment,
  onReturnShipment: actionReturnShipment,
  onChangeStatus: changeStatus,
};
let showModalValuation = ref(false);
let showModalStatus = ref(false);
function changeStatus() {
  showModalStatus.value = true;
}

onMounted(() => {
  loadShipment();
  shipmentStore.getServices();
  minDate.value = dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD");
  maxDate.value = dateUtils.formatDate(
    dateUtils.add(dateUtils.getToday(), "days", 15),
    "YYYY-MM-DD"
  );
  if (!shipmentStore.isNew) {
    tabs.value.push({ label: "Seguimiento", value: "seguimiento" });
  }
  if (isAgency.value && !shipmentStore.isNew) {
    tabs.value.push({ label: "Valoración", value: "valuations" });
  }
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

function uploadAduana() {
  fileInput.value.click();
}

const handleFileUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    console.log("envio de archivo");
    sendFile(file);
  }
  e.target.value = null;
};

const sendFile = (file) => {
  const data = {
    name: file.name,
    description: "",
    reference: "",
    origin: route.query.locator,
  };
  fileStore.uploadFile(`${urlAduana.value}`, file, data);
};
let urlAduana = ref("");
/* const url = computed(() => `shipment/${localValue.value.shipment.id}/courier/${localValue.value.shipment.courier}/paperlesss`); */

function findServiceByName(name) {
  return servicios.value?.find((service) => service.name === name) || null;
}

function getDescription(value) {
  if (!value || value === "") return null;
  const foundService = findServiceByName(value);
  return foundService ? foundService.description : null;
}

function changedSelectService(service) {
  newDescription.value = getDescription(service);
  keyLabel.value++;
}

function getValuations(id) {
  valuationStore.getValuationById(id);
}

function loadShipment() {
  if (route.params.id) {
    shipmentStore.getShipmentById(route.params.id);
    if (isAgency.value) {
      getValuations(route.params.id);
      tabs.value.push({ label: "Bultos Sorter", value: "bultos_sorter" });
    }
    shipmentStore.isNew = false;
  } else {
    shipmentStore.isNew = true;
    actions.value = [];
    localValue.value = baseShipment;
    if (!isAgency.value) {
      localValue.value.customer_id = customerIdResolver;
      getContactPickupByDefect();
    }
  }
}
function actionReturnShipment() {
  copyShipment("return");
}
function actionCopyShipment() {
  copyShipment("copy");
}
function getContactPickupByDefect() {
  shipmentStore.getContactByDefault(localValue.value.customer_id);
}

function saveItem() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        if (error.$property) tabSelected.value = "mercancias";
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    disableButton.value = true;

    formatAndCreateShipment("shipment");
  });
}

function calculateValuation() {
  const promise = validate();

  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    disableButtonValuation.value = true;
    formatAndCreateShipment("valuation");
  });
}

function formatAndCreateShipment(type) {
  const contract = objectUtils.copyByValue(localValue.value);
  if (shipmentStore.isNew) delete contract._id;
  contract.pickup.date = dateUtils.add(contract.date, "hours", 12);
  delete contract.date;

  if (!contract.features.insurance) contract.features.insurance = 0;

  if (!contract.features.refund_amount) contract.features.refund_amount = 0;

  if (type === "shipment") {
    shipmentStore.createShipment(contract);
  } else {
    shipmentStore.calculateValuation(contract);
  }
}

function cancelShipment() {
  sweetAlert({
    title: "¿Está seguro?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: "Cerrar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-danger`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      shipmentStore.cancelShipment(route.params.id);
    }
  });
}

function getShipmentsLabel() {
  shipmentStore.getShipmentLabel([route.params.id]);
}

function justificante() {
  shipmentStore.getJustificante(route.params.id);
}

function makeExport(blob) {
  let URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function loadPdfReceipt(value) {
  const binaryString = atob(value);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  makeExport(new Blob([bytes], { type: "application/pdf" }));
}
function loadHtmlReceipt(value) {
  const decodeString = atob(value);
  var w = window.open();
  w.document.write(decodeString);
}
function changedState(state) {
  showModalStatus.value = false;
  localValue.value.status = state;
}
const keyCard = ref(0);
function resetTabs() {
  tabs.value = [
    { label: "Bultos", value: "bultos" },
    { label: "Prestaciones", value: "prestaciones" },
    { label: "Mercancias", value: "mercancias" },
  ];
  tabSelected.value = "bultos";
}
function convertArrayToList(array){
  return array.reduce((acc, item) => acc + `<li>${item}</li>`, '');
}
function loadChangeStatusRule(pickup, delivery) {
  const insertLocation = actions.value.length > 3 ? 3 : 2;
  if (isAgency.value) {
    if (pickup.own == true && delivery.own == false) {
      statusChange.value = [
        { label: "Recogido", value: "RECOGIDO" },
        { label: "Incidencia", value: "INCIDENCIA" },
      ];
      actions.value[insertLocation].push({
        label: "Transitar Estado",
        event: "onChangeStatus",
      });
    } else if (delivery.own == true && pickup.own == false) {
      statusChange.value = [
        { label: "En Transito", value: "EN_TRANSITO" },
        { label: "En Reparto", value: "EN_REPARTO" },
        { label: "Entregado", value: "ENTREGADO" },
        { label: "Entrega Cancelada", value: "ENTREGA_CANCELADA" },
        { label: "Incidencia", value: "INCIDENCIA" },
      ];
      actions.value[insertLocation].push({
        label: "Transitar Estado",
        event: "onChangeStatus",
      });
    } else if (delivery.own && pickup.own) {
      actions.value[insertLocation].push({
        label: "Transitar Estado",
        event: "onChangeStatus",
      });
    }
  }
}
function copyShipment(typeAction) {
  actions.value = [];
  resetTabs();
  const infoByValue = JSON.parse(JSON.stringify(localValue.value));
  infoByValue._id = "";
  infoByValue.origin = "BEEFRONT";
  infoByValue.date = dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD");
  infoByValue.locator = "";
  infoByValue.reference = "";
  infoByValue.pickup.number = "";
  infoByValue.delivery.number = "";
  infoByValue.pickup.id = null;
  infoByValue.delivery.id = null;
  infoByValue.status = "";
  infoByValue.courier = "";
  infoByValue.description = "";
  //infoByValue.customer = null
  infoByValue.errors = [];
  infoByValue.warning = [];
  infoByValue.uri_tracking = [];
  infoByValue.account = null;
  infoByValue.metadata = null;
  infoByValue.merchandise_description = "";
  infoByValue.packages_sorter = [];
  for (let n = 0; n <= infoByValue.packages.length - 1; n += 1) {
    infoByValue.packages[n].mac = "";
    infoByValue.packages[n].locator = "";
    infoByValue.packages[n].locator2 = "";
    infoByValue.packages[n].reference = "";
    infoByValue.packages[n].items = [];
    infoByValue.packages[n].mac = "";
  }
  if (infoByValue.features) {
    infoByValue.features.refund_amount = 0;
    infoByValue.features.insurance = 0;
    infoByValue.features.delivery_on_saturday = false;
    infoByValue.features.returns = false;
  }
  infoByValue.pickup.date = dateUtils.formatDate(
    dateUtils.getToday(),
    "YYYY-MM-DD"
  );
  localValue.value = infoByValue;
  if (typeAction == "return") {
    returnShipment();
  }
  disablesInputs.value = false;
  keyCard.value++;
}

function returnShipment() {
  const infoByValue = JSON.parse(JSON.stringify(localValue.value));
  if (infoByValue.delivery.in_point) {
    localValue.value.delivery = infoByValue.pickup;
    localValue.value.pickup.address.city = "";
    localValue.value.pickup.address.postal_code = "";
    localValue.value.pickup.address.province = "";
    localValue.value.pickup.address.street = "";
  } else {
    localValue.value.pickup = infoByValue.delivery;
    localValue.value.delivery = infoByValue.pickup;
  }
}
function closeValuation() {
  showModalValuation.value = false;
}
function showButtonDocument() {
  const excludedStatuses = ["ERROR", "REGISTRADO", "TRANSMITIENDO"];
  return (
    isAgency.value &&
    localValue.value.status != "" &&
    !excludedStatuses.includes(localValue.value.status)
  );
}
const serviceCourier = computed(() => {
  if (!localValue.value.service) return "";

  const foundService = findServiceByName(localValue.value.service);
  if (!foundService) return "";
  return foundService.courier;
});
function closeModalChangeStatus() {
  showModalStatus.value = false;
}
watch(
  () => shipmentStore.shipmentLabel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
let servicios = ref([]);
watch(
  () => shipmentStore.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val;
      newDescription.value = getDescription(localValue.value?.service);
      keyLabel.value++;
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => localValue.value?.customer_id,
  (newVal) => {
    if (newVal) {
      if (!route.params.id) {
        getContactPickupByDefect();
      }
    }
  }
);

watch(
  () => shipmentStore.justificante,
  (value) => {
    if (value) {
      if (value.type == "pdf") {
        loadPdfReceipt(value.receipt);
      } else if (value.type === "html") {
        loadHtmlReceipt(value.receipt);
      } else {
        const request = value.receipt;
        window.open(request, "_blank");
      }
    } else {
      snackbar.value = {
        title: "Error",
        message: "Ha ocurrido un error.",
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.updatedShipment,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Actualizado",
      //   message: "El envío ha sido actualizado",
      //   type: "success",
      //   color: "white",
      // };
      shipmentsStore.reload = true;
      router.push("/shipments");
    }
  }
);

watch(
  () => shipmentStore.createdShipment,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Creado",
      //   message: "El envío ha sido creado",
      //   type: "success",
      //   color: "white",
      // };
      shipmentsStore.reload = true;
      router.push("/shipments");
    }
  }
);

watch(
  () => shipmentStore.canceledShipment,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Anulado",
        message: "Envío anulado",
        type: "info",
        color: "white",
      };
      setTimeout(() => {
        loadShipment();
      }, 1500);
      shipmentsStore.reload = true;
    }
  }
);

let key = ref(1);
watch(
  () => shipmentStore.defaultContact,
  (value) => {
    if (!value) return null;
    localValue.value.pickup = objectUtils.deepCopy(baseShipment.pickup, value);
    key.value += 1;
  }
);

watch(
  () => shipmentStore.error,
  (value) => {
    if (value) {
      disableButton.value = false;
      disableButtonValuation.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
const excludedStatus = ["TRANSMITIENDO", "ERROR", "REGISTRADO"];
watch(
  () => shipmentStore.shipment,
  (value) => {
    if (value) {
      const val = objectUtils.deepCopy(baseShipment, value);
      val.date = dateUtils.formatDate(value.pickup.date, "YYYY-MM-DD");
      localValue.value = val;
      disablesInputs.value = localValue.value.status != "ERROR";
      loadChangeStatusRule(localValue.value.pickup, localValue.value.delivery);
      if (
        localValue.value.status === "ERROR" ||
        localValue.value.status === "REGISTRADO"
      ) {
        actionShipments.value = true;
      }
      if (localValue.value.courier !== "PROPIO") {
        actions.value[0].push({
          label: "Justificante entrega",
          event: "onJustificantePrint",
        });
      }
      if (
        localValue.value.courier === "UPS" &&
        localValue.value.internacional &&
        !excludedStatus.includes(localValue.value.status)
      ) {
        actions.value.push([
          { label: "Enviar documento de aduanas", event: "onUploadAduana" },
        ]);
      }
      if (
        !isAgency.value &&
        (localValue.value.origin === "SHOPIFY" 
          || localValue.value.origin === "PRESTASHOP"
          || localValue.value.origin === "WOOCOMMERCE")
      ) {
        tabs.value.push({ label: "Fulfillment", value: "fulfillment" });
      }
      if (localValue.value.status == "ERROR") {
        resetTabs();
      }
      if (val) {
        urlAduana.value = `shipment/${val._id}/courier/${val.courier}/paperless`;
      }
    }
  }
);

let recogidaForm = ref(null);
let entregaForm = ref(null);
watch(
  () => valuationStore.error,
  (value) => {
    if (value) {
      disableButton.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.valuation,
  (value) => {
    if (value) {
      disableButtonValuation.value = false;
      showModalValuation.value = true;
    }
  }
);

watch(
  () => fileStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: "Ha ocurrido un error.",
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => fileStore.files,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Exitoso",
        message: "Su documento ha sido cargado..",
        type: "info",
        color: "white",
      };
    }
  }
);
watch(
  () => fileStore.uploadStatus,
  (value) => {
    if (value === "SUCCESS") {
      snackbar.value = {
        title: "Exitoso",
        message: "Su documento ha sido cargado..",
        type: "info",
        color: "white",
      };
      keyCard.value++;
    }
  }
);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <div class="card" :key="keyCard">
    <div class="card-body">
      <argon-detail
        v-if="localValue"
        :group-actions="actions"
        @click="triggerEvent"
        :show-config="actionShipments"
      >
        <template #buttons>
          <argon-loading-button
            v-if="!disablesInputs"
            class="me-1 btn-sm bg-gradient-primary"
            color="primary"
            variant="gradient"
            icon="fa fa-save"
            :loading="disableButton"
            :label="disableButton ? 'Guardando...' : 'Guardar'"
            :disable-button="disableButton"
            @click="saveItem"
          >
          </argon-loading-button>
          <argon-loading-button
            v-if="isAgency"
            class="me-1 btn-sm"
            color="primary"
            variant="outline"
            icon="fa fa-calculator"
            :loading="disableButtonValuation"
            :label="
              disableButtonValuation
                ? 'Calculando valoración'
                : 'Calcular valoración'
            "
            :disable-button="disableButtonValuation"
            @click="calculateValuation"
          >
          </argon-loading-button>
        </template>
        <template #buttons-right>
          <documents-button
            v-if="showButtonDocument()"
            label="Documentos"
            :id-entity="route.params.id"
            container="documents"
            entity="shipments"
            :origin="localValue.locator"
            class="mb-3"
          />
        </template>
        <template #body>
          <div class="row">
            <div class="col-12">
              <div class="row spacing">
                <div class="col-md-6 col-sm-12 col-lg-5">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-2">
                          <label v-if="isAgency" class="col-form-label required"
                            >Cliente</label
                          >
                          <label v-if="!isAgency" class="col-form-label"
                            >Depart.</label
                          >
                        </div>
                        <div class="col-10">
                          <customer-autocomplete
                            ref="customer"
                            v-model="localValue"
                            :readonly="disablesInputs"
                            :show-departments="true"
                            :show-clients="isAgency"
                          >
                          </customer-autocomplete>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-5">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-6 col-xl-6">
                          <div class="row">
                            <div class="col-6">
                              <label class="col-form-label required"
                                >Fecha recogida
                              </label>
                            </div>
                            <div class="col-6">
                              <argon-input
                                id="date"
                                v-model="localValue.date"
                                :is-required="true"
                                :error="v$.date.$error"
                                type="date"
                                :disabled="disablesInputs"
                                :min="minDate"
                                :max="maxDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-6 col-xl-6">
                          <div class="row">
                            <div class="col-5">
                              <label
                                :key="keyLabel"
                                v-tooltip
                                :data-bs-title="newDescription"
                                class="col-form-label required"
                                >Servicio
                                <sup
                                  v-if="localValue.service != ''"
                                  class="text-primary sup-question"
                                  >?</sup
                                ></label
                              >
                            </div>
                            <div class="col-7">
                              <argon-select-courier
                                v-if="shipmentStore.services"
                                id="contact-type"
                                v-model="localValue.service"
                                name="contact-type"
                                :is-required="true"
                                :options="servicios"
                                :error="v$.service.$error"
                                name-value="name"
                                courier="courier"
                                name-label="name"
                                description="description"
                                filterable
                                @selectedChange="changedSelectService"
                                :disabled="disablesInputs"
                                :show-icon="true"
                              >
                              </argon-select-courier>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-2">
                  <div class="card">
                    <div class="card-body">
                      <argon-input
                        class="float-start"
                        :class="{
                          'w-80':
                            localValue.warning && localValue.warning.length,
                          'w-100': !(
                            localValue.warning && localValue.warning.length
                          ),
                        }"
                        id="locator"
                        :placeholder="'Localizador'"
                        v-model="localValue.locator"
                        type="text"
                        :disabled="true"
                      />
                      <i
                        v-if="localValue.warning && localValue.warning.length"
                        v-tooltip
                        :data-bs-title="convertArrayToList(localValue.warning)"
                        class="fa fa-exclamation-triangle text-sm float-end"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
                  <div class="card">
                    <div class="card-header">Recogida</div>
                    <div class="card-body">
                      <logistics-form
                        ref="recogidaForm"
                        :key="key"
                        v-model="localValue.pickup"
                        name="RECOGIDA"
                        :readonly="disablesInputs"
                        :customer-id="localValue.customer_id"
                        :is-pickup="true"
                      >
                      </logistics-form>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
                  <div class="card">
                    <div class="card-header">Entrega</div>
                    <div class="card-body">
                      <logistics-form
                        ref="entregaForm"
                        v-model="localValue.delivery"
                        name="ENTREGA"
                        :readonly="disablesInputs"
                        :customer-id="localValue.customer_id"
                      >
                      </logistics-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #title>
          <div class="row">
            <div class="col-12">
              <argon-input
                id="reference"
                v-model="localValue.reference"
                :max-length="30"
                type="text"
                placeholder="Referencia"
                size="lg"
                font-style="h4 fw-bold"
                :disabled="disablesInputs"
              />
            </div>
          </div>
        </template>
        <template #state>
          <partner-avatar
            :item="localValue.courier"
            :zoomeable="false"
          ></partner-avatar>
          <status-badge
            v-if="localValue.status_description"
            :item="localValue.status"
            :tooltip="localValue.status_description"
          ></status-badge>
          <status-badge
            v-else
            :item="localValue.status"
            :tooltip="localValue.errors"
          ></status-badge>
        </template>
        <template #tabs>
          <div class="card mt-2">
            <div class="card-body">
              <tabs-bootstrap
                v-model="tabSelected"
                :tabs="tabs"
              ></tabs-bootstrap>
              <prestaciones
                v-show="tabSelected == 'prestaciones'"
                :service-courier="serviceCourier"
                v-model="localValue.features"
                :readonly="disablesInputs"
                :courier="localValue.courier"
              />
              <div v-if="tabSelected == 'mercancias'" class="p-1">
                <div class="row">
                  <div class="col-1">
                    <label class="col-form-label">Descripción </label>
                  </div>
                  <div class="col-4">
                    <argon-input
                      id="description"
                      v-model="localValue.merchandise_description"
                      :max-length="50"
                      type="text"
                      size="lg"
                      :disabled="disablesInputs"
                    />
                  </div>
                </div>
              </div>
              <bultos
                v-if="tabSelected == 'bultos'"
                v-model="localValue.packages"
                :readonly="disablesInputs"
              />
              <tracking
                v-if="tabSelected == 'seguimiento'"
                :id-shipment="route.params.id"
                :url="localValue.uri_tracking"
                :courier="localValue.courier"
              />
              <fulfillment-shipment
                :fulfillments="localValue.packages"
                v-if="
                  tabSelected === 'fulfillment' &&
                  localValue.packages.length > 0
                "
              />
              <valuations-sub-table
                v-if="tabSelected === 'valuations'"
                v-model="valuationStore.valuation"
                :header="false"
              />
              <packages-sorter
                :packages-sorter="localValue.packages_sorter"
                v-if="tabSelected === 'bultos_sorter'"
              />
            </div>
          </div>
        </template>
      </argon-detail>
    </div>
  </div>
  <input
    type="file"
    ref="fileInput"
    style="display: none"
    @change="handleFileUpload"
  />
  <modal-change-status
    v-if="showModalStatus"
    id="modalChangeShipmentStatusId"
    :shipment-id="route.params.id"
    :options="statusChange"
    @success-change="changedState"
    @close="closeModalChangeStatus"
  />
  <modal-calculate-valuation
    v-if="showModalValuation"
    id="modalValuation"
    @close="closeValuation"
  />

  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}

.sup-question {
  font-size: 0.9em;
}
</style>